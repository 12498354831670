import { User } from '../types/user';
import { USER_ROLE } from './user-role';

export const NO_USER: User = {
    id: -1,
    image_url: null,
    name: 'Sin asignar',
    role: USER_ROLE.SELLER,
    email: '',
    created_at: '',
    updated_at: '',
};
