import { useCallback, useEffect, useState } from 'react';
import TextInput, { TextInputProps } from '../text-input/text-input';
import className from './password-input.module.scss';
import cx from 'classnames';
import { useForm } from '../../util/use-form';
import Form from '../form/form';
import Collapsable from '../collapsable/collapsable';

interface PasswordInputProps<T extends {}, U extends keyof T, IsRequired extends boolean = false>
    extends TextInputProps<T, U, IsRequired> {
    needsRepetition?: boolean;
}

interface PasswordRepetitionForm {
    password_repetition: string;
}

const DEFAULT_PASSWORD_REPETITION_FORM: PasswordRepetitionForm = {
    password_repetition: '',
};

export default function PasswordInput<T extends {}, U extends keyof T, IsRequired extends boolean = false>(
    props: PasswordInputProps<T, U, IsRequired>
) {
    const [isInvalid, setIsInvalid] = useState<boolean>(false);
    const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
    const [isPasswordRepetitionShown, setIsPasswordRepetitionShown] = useState<boolean>(false);

    const [
        passwordRepetitionForm,
        ,
        ,
        handlePasswordRepetitionFormChange,
        handlePasswordRepetitionFormValidnessChange,
        ,
        isPasswordRepetitionFormShown,
        setIsPasswordRepetitionFormShown,
        setPasswordRepetitionForm,
    ] = useForm(DEFAULT_PASSWORD_REPETITION_FORM, false);

    const value = props.form[props.valueKey] || '';
    if (typeof value !== 'string') {
        throw new Error(`Type of ${props.valueKey} must be string`);
    }

    useEffect(() => {
        if (!isPasswordRepetitionFormShown) {
            setPasswordRepetitionForm(DEFAULT_PASSWORD_REPETITION_FORM);
        }
    }, [isPasswordRepetitionFormShown]);

    useEffect(() => {
        if (props.onValidnessChange) {
            props.onValidnessChange(props.valueKey, isInvalid);
        }
    }, [isInvalid]);

    useEffect(() => {
        if (props.needsRepetition) {
            let newIsInvalid = true;

            if (value === passwordRepetitionForm.password_repetition) {
                newIsInvalid = false;
            }

            setIsInvalid(newIsInvalid);
        }
    }, [value, passwordRepetitionForm.password_repetition]);

    useEffect(() => {
        setIsPasswordRepetitionFormShown(!!value);
    }, [value]);

    const renderActionLabel = useCallback(() => {
        return <div className={className['action-label']}>{isPasswordShown ? 'Ocultar' : 'Mostrar'}</div>;
    }, [isPasswordShown, value]);

    const renderRepetitionActionLabel = useCallback(() => {
        return <div className={className['action-label']}>{isPasswordRepetitionShown ? 'Ocultar' : 'Mostrar'}</div>;
    }, [isPasswordRepetitionShown, value]);

    const handleActionClick = () => {
        setIsPasswordShown(isPasswordShown => !isPasswordShown);
    };

    const handleRepetitionActionClick = () => {
        setIsPasswordRepetitionShown(isPasswordRepetitionShown => !isPasswordRepetitionShown);
    };

    return (
        <>
            <TextInput
                {...props}
                autoComplete={props.autoComplete || 'new-password'}
                className={cx(className.base, props.className)}
                type={isPasswordShown ? 'text' : 'password'}
                action={{
                    label: renderActionLabel(),
                    isShown: !!value,
                    onClick: handleActionClick,
                }}
            />
            {props.needsRepetition && (
                <Collapsable collapsed={!value}>
                    <TextInput<PasswordRepetitionForm, 'password_repetition', true>
                        placeholder="Repetir nueva contraseña"
                        className={cx(className.base, className['password-repetition-input'], props.className)}
                        form={passwordRepetitionForm}
                        valueKey="password_repetition"
                        isRequired={true}
                        shouldReset={!!value}
                        shouldBlur={false}
                        autoComplete="new-password"
                        type={isPasswordRepetitionShown ? 'text' : 'password'}
                        action={{
                            label: renderRepetitionActionLabel(),
                            isShown: !!passwordRepetitionForm.password_repetition,
                            onClick: handleRepetitionActionClick,
                        }}
                        isInvalid={{
                            value: isInvalid,
                            label: <>Ambas contraseñas deben ser iguales</>,
                        }}
                        onChange={handlePasswordRepetitionFormChange}
                        onSubmit={props.onSubmit}
                        onValidnessChange={handlePasswordRepetitionFormValidnessChange}
                    />
                </Collapsable>
            )}
        </>
    );
}
