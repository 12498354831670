import { DependencyList, MutableRefObject, useCallback, useRef } from 'react';

export const useCallbackRef = <T>(
    callback: (current: T | null) => void,
    deps?: DependencyList
): [MutableRefObject<T | null>, (node: T) => void] => {
    const ref = useRef<T | null>(null);
    const setRef = useCallback((node: T) => {
        ref.current = node;
        callback(ref.current);
    }, deps || []);

    return [ref, setRef];
};
