import { useState } from 'react';
import { LOCAL_STORAGE_KEY } from './local-storage-key';

export const useLocalStorageState = <T>(
    key: LOCAL_STORAGE_KEY,
    fromString: (value: string | null) => T,
    toString?: (value: T) => string
): [T, (newValue: T) => void] => {
    const [value, setValue] = useState<T>(fromString(localStorage.getItem(key)));

    const handleValueSet = (newValue: T) => {
        localStorage.setItem(key, (toString || String)(newValue));
        setValue(newValue);
    };

    return [value, handleValueSet];
};
