import className from './orders.module.scss';
import cx from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import { useCallbackRef } from '../../../util/use-callback-ref';
import { useEffect, useMemo, useState } from 'react';
import Badge from '../../badge/badge';
import { useStore } from '../../../stores';
import { observer } from 'mobx-react';
import { DateTime, Settings } from 'luxon';
import RectangleContentLoader from '../../content-loader/rectangle';
import { useResponsiveness } from '../../../providers/responsiveness-provider';
import { Event } from '../../../types/event';
import TextInput from '../../text-input/text-input';
import Form from '../../form/form';
import { useForm } from '../../../util/use-form';
import { flowResult } from 'mobx';
import NoResults from '../../no-results/no-results';
import { Order, OrderWithDetails } from '../../../types/order';
import SearchResult from '../../search-result/search-result';
import Select from '../../select/select';
import { BillingState } from '../../../types/billing-state';
import formatBillingStateName from '../../../util/format-billing-state-name';
import OrderStatus from '../../order-status/order-status';
import { ORDER_STATUS } from '../../../util/order-status';
import getOrderStatusLabel from '../../../util/get-order-status-label';

interface OrdersRouteProps {
    searchForm: OrdersRouteSearchForm;
    onOrderClick: (order: OrderWithDetails) => void;
    onEventShow: (eventId: Event['id']) => void;
    onSearchFormChange: (searchForm: OrdersRouteProps['searchForm']) => void;
}

export interface OrdersRouteSearchForm {
    id: string | null;
    productName: string | null;
    billingStateId: Order['billing_state_id'] | null;
    status: Order['status'] | null;
}

const DEFAULT_SEARCH_FORM: OrdersRouteSearchForm = {
    id: null,
    productName: null,
    billingStateId: null,
    status: null,
};

const DESKTOP_ROW_HEIGHT_PIXELS = 64;
const MOBILE_ROW_HEIGHT_PIXELS = 36;

const OrdersRoute = observer(function (props: OrdersRouteProps) {
    const { isMobile } = useResponsiveness();

    const { orderStore, billingStateStore } = useStore();
    const { orders, orderCount, isIndexing, isCounting } = orderStore;
    const { billingStates, mappedBillingStates } = billingStateStore;

    const [headersHeight, setHeadersHeight] = useState<number>(0);
    const [lastStopIndex, setLastStopIndex] = useState<number>(0);

    const [, setHeadersRef] = useCallbackRef<HTMLDivElement>(headersElement => {
        if (headersElement) {
            setHeadersHeight(headersElement.getBoundingClientRect().height);
        }
    });

    const [
        searchForm,
        isSearchFormInvalid,
        ,
        handleSearchFormChange,
        handleSearchFormValidnessChange,
        setSearchFormResetHandler,
        isSearchFormShown,
        ,
        setSearchForm,
    ] = useForm(DEFAULT_SEARCH_FORM, true);

    useEffect(() => {
        if (!props.searchForm.id && !props.searchForm.productName && orderCount === null) {
            orderStore.count();
        } else {
            setSearchForm(props.searchForm);
        }
    }, []);

    useEffect(() => {
        props.onSearchFormChange(searchForm);
    }, [searchForm]);

    const isOrderLoaded = (index: number) => {
        return orders.length > index ? !!orders[index] : false;
    };

    const loadMoreOrders = async (_: number, stopIndex: number) => {
        orderStore.index(stopIndex);
        setLastStopIndex(stopIndex);
    };

    const renderRow = ({ index, style }: ListChildComponentProps) => {
        const order = orders.length > index ? orders[index] : null;
        const billingState = order ? mappedBillingStates[order.billing_state_id] : null;

        return (isIndexing && !isOrderLoaded(index)) || isCounting ? (
            <div
                className={cx(className.row, {
                    [className.odd]: index % 2 === 0,
                })}
                style={style}
            >
                <div className={className.cell}>
                    <RectangleContentLoader width={56} height={14} />
                </div>
                <div className={cx(className.cell, className.centered)}>
                    <RectangleContentLoader width={100} height={14} />
                </div>
                {!isMobile && (
                    <>
                        <div className={cx(className.cell, className.centered)}>
                            <RectangleContentLoader width={100} height={14} />
                        </div>
                        <div className={cx(className.cell, className.centered)}>
                            <RectangleContentLoader width={80} height={20} />
                        </div>
                        <div className={className.cell}>
                            <div className={className['products-formatter']}>
                                <RectangleContentLoader className={className.product} width={100} height={20} />
                                <RectangleContentLoader className={className.product} width={80} height={20} />
                                <RectangleContentLoader className={className.product} width={100} height={20} />
                            </div>
                        </div>
                    </>
                )}
                <div className={cx(className.cell, className.right)}>
                    <RectangleContentLoader className={className.product} width={60} height={20} />
                </div>
            </div>
        ) : isOrderLoaded(index) && order !== null ? (
            <div
                className={cx(className.row, {
                    [className.odd]: index % 2 === 0,
                })}
                style={style}
                onClick={() => props.onOrderClick(order)}
            >
                <div className={className.cell}>
                    #<SearchResult searchText={searchForm.id}>{`${order.id}`}</SearchResult>
                </div>
                <div className={cx(className.cell, className.centered)}>
                    {DateTime.fromISO(order.at).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                </div>
                {!isMobile && (
                    <>
                        <div className={cx(className.cell, className.centered)}>
                            {billingState && formatBillingStateName(billingState)}
                        </div>
                        <div className={cx(className.cell, className.centered)}>
                            <OrderStatus>{order.status}</OrderStatus>
                        </div>
                        <div className={className.cell}>
                            <div className={className['products-formatter']}>
                                {(searchForm.productName
                                    ? [...order.products].sort(([, productNameA]) => {
                                          return productNameA
                                              .toLowerCase()
                                              .includes(searchForm.productName!.toLowerCase())
                                              ? -1
                                              : 0;
                                      })
                                    : order.products
                                ).map(([, productName], index) => (
                                    <Badge className={className.product} key={`product-${index}`}>
                                        <SearchResult searchText={searchForm.productName}>{productName}</SearchResult>
                                    </Badge>
                                ))}
                            </div>
                        </div>
                    </>
                )}
                <div className={cx(className.cell, className.right)}>
                    <Badge>
                        {(order.total / 100).toLocaleString(Settings.defaultLocale, {
                            style: 'currency',
                            currency: 'GTQ',
                        })}
                    </Badge>
                </div>
            </div>
        ) : null;
    };

    const handleSearchFormAutoSubmit = () => {
        if (
            searchForm.id ||
            searchForm.productName ||
            searchForm.billingStateId !== null ||
            searchForm.status !== null
        ) {
            orderStore.index(null, searchForm);
        } else {
            orderStore.reset();
            flowResult(orderStore.count()).then(successful => {
                if (successful) {
                    orderStore.index(lastStopIndex);
                }
            });
        }
    };

    const rowHeight = useMemo(() => (isMobile ? MOBILE_ROW_HEIGHT_PIXELS : DESKTOP_ROW_HEIGHT_PIXELS), [isMobile]);

    return (
        <div className={className.base}>
            <Form
                className={className['search-form']}
                form={searchForm}
                isInvalid={isSearchFormInvalid}
                isShown={isSearchFormShown}
                onChange={handleSearchFormChange}
                setFormResetHandler={setSearchFormResetHandler}
                onValidnessChange={handleSearchFormValidnessChange}
                onAutoSubmit={handleSearchFormAutoSubmit}
            >
                {inputProps => (
                    <>
                        <TextInput
                            {...inputProps}
                            className={className.filter}
                            valueKey="id"
                            placeholder="Buscar pedido"
                        />
                        {!isMobile && (
                            <>
                                <Select
                                    {...inputProps}
                                    className={className.filter}
                                    placeholder="Buscar lugar"
                                    value={
                                        searchForm.billingStateId
                                            ? {
                                                  value: searchForm.billingStateId,
                                                  label: formatBillingStateName(
                                                      mappedBillingStates[searchForm.billingStateId]!
                                                  ),
                                              }
                                            : null
                                    }
                                    options={billingStates.map(billingState => ({
                                        value: billingState.id,
                                        label: formatBillingStateName(billingState),
                                    }))}
                                    onChange={billingStateId =>
                                        inputProps.onChange(
                                            'billingStateId',
                                            billingStateId as BillingState['id'] | null
                                        )
                                    }
                                />
                                <Select
                                    {...inputProps}
                                    className={className.filter}
                                    placeholder="Buscar estado"
                                    value={
                                        searchForm.status
                                            ? {
                                                  value: searchForm.status,
                                                  label: getOrderStatusLabel(searchForm.status),
                                              }
                                            : null
                                    }
                                    options={Object.values(ORDER_STATUS).map(orderStatus => ({
                                        value: orderStatus,
                                        label: getOrderStatusLabel(orderStatus),
                                    }))}
                                    formatOptionLabel={{
                                        asElement: ({ value: orderStatus }) => <OrderStatus>{orderStatus}</OrderStatus>,
                                        asString: ({ label: orderStatus }) => orderStatus,
                                    }}
                                    onChange={orderStatus =>
                                        inputProps.onChange('status', orderStatus as ORDER_STATUS | null)
                                    }
                                />
                                <TextInput
                                    {...inputProps}
                                    className={className.filter}
                                    valueKey="productName"
                                    placeholder="Buscar productos"
                                />
                            </>
                        )}
                    </>
                )}
            </Form>
            {orderCount === null || orderCount > 0 || isIndexing || isCounting ? (
                <div className={className.table}>
                    <div ref={setHeadersRef} className={className.headers}>
                        <div className={className.header}>Pedido</div>
                        <div className={cx(className.header, className.centered)}>Fecha</div>
                        {!isMobile && (
                            <>
                                <div className={cx(className.header, className.centered)}>Lugar</div>
                                <div className={cx(className.header, className.centered)}>Estado</div>
                                <div className={className.header}>Productos</div>
                            </>
                        )}
                        <div className={cx(className.header, className.right)}>Total</div>
                    </div>
                    <AutoSizer>
                        {({ height: containerHeight, width }) => {
                            const height = Math.min(
                                containerHeight - 2,
                                (orderCount || 0) * rowHeight + headersHeight + 1
                            );

                            return (
                                <InfiniteLoader
                                    isItemLoaded={isOrderLoaded}
                                    itemCount={orderCount || 0}
                                    loadMoreItems={loadMoreOrders}
                                >
                                    {({ onItemsRendered, ref }) => (
                                        <List
                                            className={className.body}
                                            onItemsRendered={onItemsRendered}
                                            ref={ref}
                                            width={width - 2}
                                            height={height - headersHeight}
                                            itemCount={orderCount || 3}
                                            itemSize={rowHeight}
                                        >
                                            {renderRow}
                                        </List>
                                    )}
                                </InfiniteLoader>
                            );
                        }}
                    </AutoSizer>
                </div>
            ) : (
                <NoResults />
            )}
        </div>
    );
});

export default OrdersRoute;
