import className from './spinner.module.scss';
import cx from 'classnames';

interface SpinnerProps {
    className?: string;
}

export default function Spinner(props: SpinnerProps) {
    return <div className={cx(className.base, props.className)} />;
}
