import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faTimes,
    faCheckCircle,
    faTimesCircle,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faArrowLeft,
    faPhone,
    faClock,
    faBell,
    faHeart,
    faSatellite,
    faStore,
} from '@fortawesome/free-solid-svg-icons';

import {
    faCalendar,
    faUser,
    faEnvelope,
    faFlag,
    faCopyright,
    faHandshake,
    faListAlt,
} from '@fortawesome/free-regular-svg-icons';

export class FontAwesomeLibrary {
    static initializeFontAwesomeLibrary = () => {
        library.add(
            faCheckCircle,
            faTimesCircle,
            faTimes,
            faCalendar,
            faUser,
            faEnvelope,
            faFlag,
            faChevronLeft,
            faChevronRight,
            faChevronDown,
            faArrowLeft,
            faPhone,
            faClock,
            faBell,
            faCopyright,
            faHeart,
            faSatellite,
            faHandshake,
            faListAlt
        );
    };
}
