import { ReactNode } from 'react';
import className from './sidebar.module.scss';
import cx from 'classnames';

export enum SIDEBAR_POSITION {
    LEFT = 'left',
    RIGHT = 'right',
}

interface SidebarProps {
    isOpen: boolean;
    children: ReactNode;
    position: SIDEBAR_POSITION;
    className?: string;
    onClose: () => void;
}

export default function Sidebar(props: SidebarProps) {
    return (
        <div
            className={cx(className.base, props.position, props.className, {
                [className.open]: props.isOpen,
                [className.left]: props.position === SIDEBAR_POSITION.LEFT,
                [className.right]: props.position === SIDEBAR_POSITION.RIGHT,
            })}
        >
            {props.children}
        </div>
    );
}
