import className from './order-history.module.scss';
import { useStore } from '../../stores';
import { Customer } from '../../types/customer';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Order } from '../../types/order';
import OrderSummary from '../order-summary/order-summary';

interface OrderHistoryProps {
    customerEmail: Customer['email'];
    height?: string;
}

const OrderHistory = observer(function (props: OrderHistoryProps) {
    const { orderStore } = useStore();
    const { ordersByCustomer: orders, isIndexingByCustomer: isIndexing } = orderStore;

    useEffect(() => {
        orderStore.indexByCustomer(props.customerEmail);
    }, [props.customerEmail]);

    return (
        <div className={className.base} style={{ height: props.height }}>
            {(isIndexing ? new Array(3).fill(null) : orders).map((order: Order | null, index) => (
                <OrderSummary
                    key={`${className.order}-${order ? order.id : index}`}
                    className={className.order}
                    order={order}
                />
            ))}
        </div>
    );
});

export default OrderHistory;
