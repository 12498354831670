import { User } from '../../types/user';
import Image from '../image/image';
import className from './user-image.module.scss';
import cx from 'classnames';

interface UserImageProps {
    user: User | null;
    size: number;
    className?: string;
}

export default function UserImage(props: UserImageProps) {
    const acronymLength = props.size > 24 ? 2 : 1;

    return (
        <Image
            url={props.user ? props.user.image_url : null}
            width={props.size}
            className={cx(className.base, props.className)}
        >
            <div className={className.fallback} style={{ width: props.size, height: props.size }}>
                {props.user
                    ? props.user.name
                          .split(' ')
                          .reduce(
                              (acronym, namePart, index) =>
                                  index < acronymLength ? `${acronym}${namePart.substr(0, 1)}` : acronym,
                              ''
                          )
                    : ''}
            </div>
        </Image>
    );
}
