import className from './user-image-input.module.scss';
import cx from 'classnames';
import { User } from '../../types/user';
import UserImage from '../user-image/user-image';
import { ChangeEvent } from 'react';
import { useStore } from '../../stores';
import { observer } from 'mobx-react';

interface UserImageInputProps<T, U extends keyof T> {
    valueKey: U;
    form: T;
    user: User | null;
    className?: string;
    onChange: (key: U, value: string | null) => void;
}

export default observer(function UserImageInput<T, U extends keyof T>(props: UserImageInputProps<T, U>) {
    const { userStore } = useStore();

    const value = props.form[props.valueKey] || '';
    if (typeof value !== 'string') {
        throw new Error(`Type of ${props.valueKey} must be string`);
    }

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (props.user && event.target.files && event.target.files.length > 0) {
            userStore.updateImage(props.user.id, event.target.files[0]);
        }
    };

    return (
        <div className={cx(className.base, props.className)}>
            <UserImage className={className.image} user={props.user} size={120} />
            <input id="image-input" onChange={handleImageChange} type="file" accept="image/*" />
            <label className={className.mask} htmlFor="image-input">
                Cambiar foto
            </label>
        </div>
    );
});
