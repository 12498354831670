import { ReactNode, useEffect, useRef, useState } from 'react';
import { useCallbackRef } from '../../util/use-callback-ref';
import ResizeObserver from 'resize-observer-polyfill';
import className from './collapsable.module.scss';
import cx from 'classnames';

interface CollapsableProps {
    collapsed: boolean;
    children: ReactNode;
    className?: string;
    id?: string;
    bodyClassName?: string;
}

export default function Collapsable(props: CollapsableProps) {
    const [bodyHeight, setBodyHeight] = useState<number | 'initial'>('initial');
    const parentResizeObserver = useRef<ResizeObserver | null>(null);
    const bodyResizeObserver = useRef<ResizeObserver | null>(null);

    useEffect(() => {
        return () => {
            if (parentResizeObserver.current) {
                parentResizeObserver.current.disconnect();
            }
            if (bodyResizeObserver.current) {
                bodyResizeObserver.current.disconnect();
            }
        };
    }, []);

    const [bodyRef, setBodyRef] = useCallbackRef<HTMLDivElement>(bodyElement => {
        if (bodyElement) {
            setBodyHeight(bodyElement.scrollHeight);
            bodyResizeObserver.current = new ResizeObserver(() => {
                if (bodyRef.current) {
                    setBodyHeight(bodyRef.current.scrollHeight);
                }
            });
            bodyResizeObserver.current.observe(bodyElement);
        }
    });

    const [, setCollapsableRef] = useCallbackRef<HTMLDivElement>(collapsableElement => {
        if (collapsableElement) {
            const parentElement = collapsableElement.parentElement;
            if (parentElement) {
                parentResizeObserver.current = new ResizeObserver(() => {
                    if (bodyRef.current) {
                        setBodyHeight(bodyRef.current.scrollHeight);
                    }
                });
                parentResizeObserver.current.observe(parentElement);
            }
        }
    });

    return (
        <div
            ref={setCollapsableRef}
            id={props.id}
            className={cx(className.base, props.className, {
                [className.collapsed]: props.collapsed,
            })}
            style={{
                maxHeight: props.collapsed ? 0 : bodyHeight,
            }}
        >
            <div ref={setBodyRef} className={cx(className.body, props.bodyClassName)}>
                {props.children}
            </div>
        </div>
    );
}
