import { ReactNode, useEffect, useState } from 'react';
import className from './image.module.scss';
import cx from 'classnames';
import { API_URL } from '../../util/api-url';

interface ImageProps {
    url: string | null;
    width: number | string;
    height?: number | string;
    children?: ReactNode;
    className?: string;
    isLocal?: boolean;
    onLoad?: () => void;
}

export default function Image(props: ImageProps) {
    const [isShown, setIsShown] = useState<boolean>(!props.url);
    const [withError, setWithError] = useState<boolean>(false);

    useEffect(() => {
        setWithError(false);
    }, [props.url]);

    const handleLoad = () => {
        setIsShown(true);
        if (props.onLoad) {
            props.onLoad();
        }
    };

    const handleError = () => {
        setIsShown(true);
        setWithError(true);
    };

    return (
        <div
            className={cx(className.base, props.className, {
                [className.shown]: isShown,
            })}
            style={{
                backgroundImage: props.url
                    ? props.isLocal
                        ? `url(${props.url})`
                        : `url(${API_URL}/${props.url})`
                    : undefined,
                width: props.width,
                height: props.height || props.width,
            }}
        >
            {(withError || !props.url) && props.children}
            {props.url && (
                <img
                    src={props.isLocal ? props.url : `${API_URL}/${props.url}`}
                    onLoad={handleLoad}
                    onError={handleError}
                />
            )}
        </div>
    );
}
