import { MouseEvent, useMemo } from 'react';
import { useResponsiveness } from '../../providers/responsiveness-provider';
import { getOS, OS } from '../../util/get-os';
import Link from '../link/link';
import className from './phone-numbers.module.scss';

interface PhoneNumbersProps {
    children: string;
}

export default function PhoneNumbers(props: PhoneNumbersProps) {
    const { isMobile } = useResponsiveness();

    const handleClick = (event: MouseEvent<HTMLDivElement>, phoneNumber: string) => {
        event.stopPropagation();

        const os = getOS();
        if (isMobile && (os === OS.IOS || os === OS.ANDROID)) {
            window.open(`tel:${phoneNumber}`, '_self');
        } else {
            window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}`);
        }
    };

    const phoneNumbers = useMemo(
        () =>
            props.children.split('/').map(phoneNumber =>
                phoneNumber
                    .replace(/^0+/, '')
                    .replace(/\s/g, '')
                    .replace(/^(\(?\+?502\)?)/, '')
                    .replace(/\-/g, '')
                    .replace(/\./g, '')
            ),
        [props.children]
    );

    return (
        <div className={className.base}>
            {phoneNumbers.length === 1 && phoneNumbers[0].length > 8
                ? props.children
                : phoneNumbers.map(phoneNumber => (
                      <Link
                          key={phoneNumber}
                          className={className['phone-number']}
                          onClick={event => handleClick(event, `+502${phoneNumber}`)}
                      >
                          {phoneNumber.substr(0, 4)}-{phoneNumber.substr(4, 4)}
                      </Link>
                  ))}
        </div>
    );
}
