import ContentLoader from 'react-content-loader';
import { COLOR } from '../../util/color';

interface RectangleContentLoaderProps {
    width: number;
    height: number;
    className?: string;
}

export default function RectangleContentLoader(props: RectangleContentLoaderProps) {
    return (
        <ContentLoader
            className={props.className}
            speed={2}
            width={props.width}
            height={props.height}
            viewBox={`0 0 ${props.width} ${props.height}`}
            backgroundColor={COLOR.GRAY_100}
            foregroundColor={COLOR.GRAY_50}
        >
            <rect x={0} y={0} rx={5} ry={5} width={props.width} height={props.height} />
        </ContentLoader>
    );
}
