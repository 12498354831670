import className from './link.module.scss';
import cx from 'classnames';
import { MouseEvent, ReactNode } from 'react';

interface LinkProps {
    children: ReactNode;
    className?: string;
    onClick: (event: MouseEvent<HTMLDivElement>) => void;
}

export default function Link(props: LinkProps) {
    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        props.onClick(event);
    };

    return (
        <div className={cx(className.base, props.className)} onClick={handleClick}>
            {props.children}
        </div>
    );
}
