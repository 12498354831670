import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import Collapsable from '../collapsable/collapsable';
import cx from 'classnames';
import className from './text-input.module.scss';
import { useCallbackRef } from '../../util/use-callback-ref';

export interface TextInputProps<T, U extends keyof T, IsRequired extends boolean> {
    valueKey: U;
    form: T;
    shouldReset: boolean;
    shouldBlur: boolean;
    placeholder?: JSX.Element | string;
    type?: 'text' | 'password';
    isRequired?: IsRequired;
    className?: string;
    name?: string;
    autoComplete?: string;
    isInvalid?: {
        value: boolean;
        label: JSX.Element;
    };
    action?: {
        label: JSX.Element;
        isShown?: boolean;
        onClick: () => void;
    };
    onChange: IsRequired extends true ? (key: U, value: string) => void : (key: U, value: string | null) => void;
    onValidnessChange?: (key: U, isInvalid: boolean) => void;
    onSubmit?: () => void;
}

export default function TextInput<T extends {}, U extends keyof T, IsRequired extends boolean = false>(
    props: TextInputProps<T, U, IsRequired>
) {
    const value = props.form[props.valueKey] || '';
    if (typeof value !== 'string') {
        throw new Error(`Type of ${props.valueKey} must be string`);
    }

    const [isInvalid, setIsInvalid] = useState<boolean>(false);
    const [showAsInvalid, setShowAsInvalid] = useState<boolean>(false);
    const [isBlurred, setIsBlurred] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [actionWidth, setActionWidth] = useState<number>(0);

    const [, setActionRef] = useCallbackRef<HTMLDivElement>(actionElement => {
        if (actionElement) {
            setActionWidth(actionElement.getBoundingClientRect().width);
        }
    });

    useEffect(() => {
        setShowAsInvalid((!!value || isBlurred) && isInvalid);
    }, [isBlurred, isInvalid, value]);

    useEffect(() => {
        if (props.shouldReset) {
            setIsBlurred(false);
        }
    }, [props.shouldReset]);

    useEffect(() => {
        if (props.shouldBlur) {
            setIsBlurred(true);
        }
    }, [props.shouldBlur]);

    useEffect(() => {
        if (props.onValidnessChange) {
            props.onValidnessChange(props.valueKey, isInvalid);
        }
    }, [isInvalid]);

    useEffect(() => {
        setIsInvalid(
            props.isRequired
                ? !props.isInvalid
                    ? !value
                    : props.isInvalid.value
                : value
                ? props.isInvalid
                    ? props.isInvalid.value
                    : false
                : false
        );
    }, [props.isInvalid, props.isRequired, value]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (props.isRequired) {
            props.onChange(props.valueKey, event.currentTarget.value);
        } else {
            (props.onChange as TextInputProps<T, U, false>['onChange'])(
                props.valueKey,
                event.currentTarget.value || null
            );
        }
    };

    const handleBlur = () => {
        setIsBlurred(true);
        setIsFocused(false);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        if (props.onSubmit && event.key === 'Enter') {
            event.preventDefault();
            props.onSubmit();
        }
    };

    return (
        <div
            className={cx(className.base, props.className, {
                [className.empty]: !value,
                [className.invalid]: showAsInvalid,
                [className.focused]: isFocused,
            })}
            onClick={event => event.stopPropagation()}
        >
            <div className={className.body}>
                <div className={className.border} />
                <div className={cx(className.input, { [className['with-placeholder']]: props.placeholder })}>
                    <input
                        type={props.type || 'text'}
                        name={props.name}
                        value={value}
                        style={{
                            paddingRight: props.action ? 16 + actionWidth : undefined,
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        autoComplete={props.autoComplete}
                        onKeyDown={event => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                            }
                        }}
                        onKeyUp={handleKeyUp}
                    />
                    {props.placeholder && (
                        <div className={className.placeholder}>
                            {props.placeholder}
                            {props.isRequired && <div className={className['required-indicator']}>*</div>}
                        </div>
                    )}
                </div>
                {props.action && (
                    <div
                        ref={setActionRef}
                        className={cx(className.action, {
                            [className.shown]: props.action.isShown,
                        })}
                        onClick={props.action.onClick}
                    >
                        {props.action.label}
                    </div>
                )}
            </div>
            {(props.isInvalid || props.isRequired) && (
                <Collapsable collapsed={!showAsInvalid} className={className['invalid-indicator']}>
                    {props.isInvalid ? (
                        props.isRequired && !value ? (
                            <>El campo es requerido</>
                        ) : (
                            props.isInvalid.label
                        )
                    ) : (
                        <>El campo es requerido</>
                    )}
                </Collapsable>
            )}
        </div>
    );
}
