import className from './badge.module.scss';
import cx from 'classnames';
import { ReactNode } from 'react';

export enum BADGE_TYPE {
    WARNING = 'warning',
}

export enum BADGE_COLOR {
    GRAY = 'gray',
    GREEN = 'green',
    YELLOW = 'yellow',
    RED = 'red',
}

interface BadgeProps {
    className?: string;
    children: ReactNode;
    fontSize?: number;
    type?: BADGE_TYPE | null;
    color?: BADGE_COLOR;
}

export default function Badge(props: BadgeProps) {
    return (
        <div
            className={cx(className.base, props.className, className[props.color || BADGE_COLOR.GRAY])}
            style={{ fontSize: props.fontSize }}
        >
            {props.children}
            {props.type && (
                <div
                    className={cx(className['type-indicator'], props.type ? { [className[props.type]]: true } : null)}
                />
            )}
        </div>
    );
}
