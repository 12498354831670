import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

interface ResponsiveProviderProps {
    children: ReactNode;
}

const MOBILE_SCREEN_WIDTH_THRESHOLD = 900;

export const ResponsivenessContext = createContext<{ isMobile: boolean }>({
    isMobile: false,
});

export const useResponsiveness = () => {
    const context = useContext(ResponsivenessContext);
    if (!context) {
        throw new Error('useResponsiveness must be used within a ResponsivenessProvider');
    } else {
        return context;
    }
};

export default function ResponsivenessProvider(props: ResponsiveProviderProps) {
    const [isMobile, setIsMobile] = useState<boolean>(false);

    const setNewIsMobile = useCallback(() => {
        setIsMobile(window.innerWidth < MOBILE_SCREEN_WIDTH_THRESHOLD);
    }, []);

    const setCssVH = useCallback(() => {
        const vh = window.innerHeight / 100;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, []);

    useEffect(() => {
        setNewIsMobile();
        window.addEventListener('resize', setNewIsMobile);

        setCssVH();
        window.addEventListener('resize', setCssVH);

        return () => {
            window.removeEventListener('resize', setNewIsMobile);
            window.removeEventListener('resize', setCssVH);
        };
    }, []);

    return (
        <ResponsivenessContext.Provider
            value={{
                isMobile,
            }}
        >
            {props.children}
        </ResponsivenessContext.Provider>
    );
}
