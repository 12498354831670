import className from './text-area.module.scss';
import cx from 'classnames';
import { ChangeEvent, useEffect, useState } from 'react';

interface TextAreaProps<T, U extends keyof T, IsRequired extends boolean> {
    valueKey: U;
    form: T;
    shouldReset: boolean;
    shouldBlur: boolean;
    isRequired?: IsRequired;
    isDisabled?: boolean;
    className?: string;
    onChange: IsRequired extends true ? (key: U, value: string) => void : (key: U, value: string | null) => void;
    onValidnessChange?: (key: U, isInvalid: boolean) => void;
}

export default function TextArea<T extends {}, U extends keyof T, IsRequired extends boolean = false>(
    props: TextAreaProps<T, U, IsRequired>
) {
    const value = props.form[props.valueKey] || '';
    if (typeof value !== 'string') {
        throw new Error(`Type of ${props.valueKey} must be string`);
    }

    const [isInvalid, setIsInvalid] = useState<boolean>(false);
    const [isBlurred, setIsBlurred] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    useEffect(() => {
        if (props.shouldReset) {
            setIsBlurred(false);
        }
    }, [props.shouldReset]);

    useEffect(() => {
        if (props.shouldBlur) {
            setIsBlurred(true);
        }
    }, [props.shouldBlur]);

    useEffect(() => {
        if (props.onValidnessChange) {
            props.onValidnessChange(props.valueKey, isInvalid);
        }
    }, [isInvalid]);

    useEffect(() => {
        if (props.isRequired) {
            setIsInvalid(!value);
        }
    }, [value, props.isRequired]);

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        if (props.isRequired) {
            props.onChange(props.valueKey, event.currentTarget.value);
        } else {
            (props.onChange as TextAreaProps<T, U, false>['onChange'])(
                props.valueKey,
                event.currentTarget.value || null
            );
        }
    };

    const handleBlur = () => {
        setIsBlurred(true);
        setIsFocused(false);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    return (
        <div
            className={cx(className.base, props.className, {
                [className.empty]: !value,
                [className.invalid]: isInvalid,
                [className.focused]: isFocused,
                [className.disabled]: props.isDisabled,
            })}
            onClick={event => event.stopPropagation()}
        >
            <div className={className.border} />
            <textarea
                value={value}
                onBlur={handleBlur}
                onFocus={handleFocus}
                disabled={props.isDisabled}
                onChange={handleChange}
            />
        </div>
    );
}
