import className from './customers.module.scss';
import cx from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import { useCallbackRef } from '../../../util/use-callback-ref';
import { useEffect, useState } from 'react';
import Badge, { BADGE_TYPE } from '../../badge/badge';
import { useStore } from '../../../stores';
import { observer } from 'mobx-react';
import { DateTime } from 'luxon';
import RectangleContentLoader from '../../content-loader/rectangle';
import PhoneNumbers from '../../phone-numbers/phone-numbers';
import { useResponsiveness } from '../../../providers/responsiveness-provider';
import { Customer, CustomerWithLatestDetails } from '../../../types/customer';
import Link from '../../link/link';
import EventIdFormatter from '../../event-id-formatter/event-id-formatter';
import { Event } from '../../../types/event';
import TextInput from '../../text-input/text-input';
import Form from '../../form/form';
import { useForm } from '../../../util/use-form';
import { flowResult } from 'mobx';
import NoResults from '../../no-results/no-results';
import SearchResult from '../../search-result/search-result';

interface CustomersRouteProps {
    searchForm: CustomersRouteSearchForm;
    onCustomerClick: (customer: CustomerWithLatestDetails) => void;
    onLatestEventShow: (eventId: Event['id']) => void;
    onSearchFormChange: (searchForm: CustomersRouteProps['searchForm']) => void;
}

export interface CustomersRouteSearchForm {
    customer: Customer['name'] | Customer['email'] | null;
}

const DEFAULT_SEARCH_FORM: CustomersRouteSearchForm = {
    customer: null,
};

const CustomersRoute = observer(function (props: CustomersRouteProps) {
    const { isMobile } = useResponsiveness();

    const { customerStore } = useStore();
    const { customers, customerCount, isIndexing, isCounting } = customerStore;

    const [headersHeight, setHeadersHeight] = useState<number>(0);
    const [lastStopIndex, setLastStopIndex] = useState<number>(0);

    const [, setHeadersRef] = useCallbackRef<HTMLDivElement>(headersElement => {
        if (headersElement) {
            setHeadersHeight(headersElement.getBoundingClientRect().height);
        }
    });

    const [
        searchForm,
        isSearchFormInvalid,
        ,
        handleSearchFormChange,
        handleSearchFormValidnessChange,
        setSearchFormResetHandler,
        isSearchFormShown,
        ,
        setSearchForm,
    ] = useForm(DEFAULT_SEARCH_FORM, true);

    useEffect(() => {
        if (!props.searchForm.customer && customerCount === null) {
            customerStore.count();
        } else {
            setSearchForm(props.searchForm);
        }
    }, []);

    useEffect(() => {
        props.onSearchFormChange(searchForm);
    }, [searchForm]);

    const isCustomerLoaded = (index: number) => {
        return customers.length > index ? !!customers[index] : false;
    };

    const loadMoreCustomers = async (_: number, stopIndex: number) => {
        customerStore.index(stopIndex);
        setLastStopIndex(stopIndex);
    };

    const renderLastOrderAtBadge = (lastOrderAt: CustomerWithLatestDetails['last_order_at']) => {
        const days = Math.floor(DateTime.now().diff(DateTime.fromISO(lastOrderAt), 'days').toObject().days!);
        return (
            <Badge fontSize={12} type={days >= 30 ? BADGE_TYPE.WARNING : null}>
                hace {days} días
            </Badge>
        );
    };

    const renderRow = ({ index, style }: ListChildComponentProps) => {
        const customer = customers.length > index ? customers[index] : null;

        return (isIndexing && !isCustomerLoaded(index)) || isCounting ? (
            <div
                className={cx(className.row, {
                    [className.odd]: index % 2 === 0,
                })}
                style={style}
            >
                <div className={className.cell}>
                    <div className={className['client-formatter']}>
                        <RectangleContentLoader width={100} height={14} />
                        <div className={className.email} style={{ marginTop: 4 }}>
                            <RectangleContentLoader width={160} height={12} />
                        </div>
                    </div>
                </div>
                {!isMobile && (
                    <>
                        <div className={cx(className.cell, className.centered)}>
                            <RectangleContentLoader width={100} height={14} />
                        </div>
                        <div className={cx(className.cell, className.centered)}>
                            <RectangleContentLoader width={100} height={14} />
                        </div>
                    </>
                )}
                <div className={cx(className.cell, className.centered)}>
                    <RectangleContentLoader width={84} height={21} />
                </div>
            </div>
        ) : isCustomerLoaded(index) && customer !== null ? (
            <div
                className={cx(className.row, {
                    [className.odd]: index % 2 === 0,
                })}
                onClick={() => props.onCustomerClick(customer)}
                style={
                    isMobile
                        ? {
                              ...style,
                              gridTemplateColumns: '1fr 160px',
                          }
                        : style
                }
            >
                <div className={className.cell}>
                    <div className={className['client-formatter']}>
                        <div className={className.name}>
                            <SearchResult searchText={searchForm.customer}>{customer.name}</SearchResult>
                        </div>
                        <div className={className.email}>{customer.email}</div>
                    </div>
                </div>
                {!isMobile && (
                    <>
                        <div className={cx(className.cell, className.centered)}>
                            {customer.last_event_id && (
                                <Link onClick={() => props.onLatestEventShow(customer.last_event_id)}>
                                    <EventIdFormatter>{customer.last_event_id}</EventIdFormatter>
                                </Link>
                            )}
                        </div>
                        <div className={cx(className.cell, className.centered)}>
                            <PhoneNumbers>{customer.phone_number}</PhoneNumbers>
                        </div>
                    </>
                )}
                <div className={cx(className.cell, className.centered)}>
                    {customer.last_order_at && renderLastOrderAtBadge(customer.last_order_at)}
                </div>
            </div>
        ) : null;
    };

    const handleSearchFormAutoSubmit = () => {
        if (searchForm.customer) {
            customerStore.index(null, searchForm.customer);
        } else {
            customerStore.reset();
            flowResult(customerStore.count()).then(successful => {
                if (successful) {
                    customerStore.index(lastStopIndex);
                }
            });
        }
    };

    return (
        <div className={className.base}>
            <Form
                className={className['search-form']}
                form={searchForm}
                isInvalid={isSearchFormInvalid}
                isShown={isSearchFormShown}
                onChange={handleSearchFormChange}
                setFormResetHandler={setSearchFormResetHandler}
                onValidnessChange={handleSearchFormValidnessChange}
                onAutoSubmit={handleSearchFormAutoSubmit}
            >
                {inputProps => <TextInput {...inputProps} valueKey="customer" placeholder="Buscar cliente" />}
            </Form>
            {customerCount === null || customerCount > 0 || isIndexing || isCounting ? (
                <div className={className.table}>
                    <div
                        ref={setHeadersRef}
                        className={className.headers}
                        style={
                            isMobile
                                ? {
                                      gridTemplateColumns: '1fr 160px',
                                  }
                                : undefined
                        }
                    >
                        <div className={className.header}>Cliente</div>
                        {!isMobile && (
                            <>
                                <div className={cx(className.header, className.centered)}>Último recordatorio</div>
                                <div className={cx(className.header, className.centered)}>Teléfono</div>
                            </>
                        )}
                        <div className={cx(className.header, className.centered)}>Últ. compra</div>
                    </div>
                    <AutoSizer>
                        {({ height: containerHeight, width }) => {
                            const height = Math.min(containerHeight - 1, (customerCount || 0) * 50 + 31);

                            return (
                                <InfiniteLoader
                                    isItemLoaded={isCustomerLoaded}
                                    itemCount={customerCount || 0}
                                    loadMoreItems={loadMoreCustomers}
                                >
                                    {({ onItemsRendered, ref }) => (
                                        <List
                                            className={className.body}
                                            onItemsRendered={onItemsRendered}
                                            ref={ref}
                                            width={width - 2}
                                            height={height - headersHeight}
                                            itemCount={customerCount || 3}
                                            itemSize={49}
                                        >
                                            {renderRow}
                                        </List>
                                    )}
                                </InfiniteLoader>
                            );
                        }}
                    </AutoSizer>
                </div>
            ) : (
                <NoResults />
            )}
        </div>
    );
});

export default CustomersRoute;
