import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useResponsiveness } from '../../providers/responsiveness-provider';
import className from './footer.module.scss';

export default function Footer() {
    const { isMobile } = useResponsiveness();

    return (
        <div className={className.base}>
            Banana-Pet <FontAwesomeIcon icon={['far', 'copyright']} /> 2021 - {new Date().getFullYear()} • Hecho con{' '}
            <FontAwesomeIcon icon="heart" />
            por Henry Campos
            {!isMobile && ` • ${process.env.REACT_APP_GIT_COMMIT_SHA}`}
        </div>
    );
}
