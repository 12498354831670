import className from './customer-sidebar.module.scss';
import Sidebar, { SIDEBAR_POSITION } from '../sidebar/sidebar';
import { useEffect, useState } from 'react';
import { Customer, CustomerWithLatestDetails } from '../../types/customer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SidebarDetail from '../sidebar/sidebar-detail';
import UserSelect from '../user-select/user-select';
import OrderHistory from '../order-history/order-history';
import { useCallbackRef } from '../../util/use-callback-ref';
import PhoneNumbers from '../phone-numbers/phone-numbers';
import Link from '../link/link';
import EventIdFormatter from '../event-id-formatter/event-id-formatter';
import { Event } from '../../types/event';

interface CustomerSidebarProps {
    customer: CustomerWithLatestDetails | null;
    onCustomerChange: (customer: Partial<Customer> & Pick<Customer, 'email'>) => void;
    onLatestEventShow: (eventId: Event['id']) => void;
    onClose: () => void;
}

export default function CustomerSidebar(props: CustomerSidebarProps) {
    const [customer, setCustomer] = useState<CustomerWithLatestDetails | null>(null);
    const [topSectionHeight, setTopSectionHeight] = useState<number>(0);

    const [, setTopSectionRef] = useCallbackRef<HTMLDivElement>(topSectionElement => {
        if (topSectionElement) {
            setTopSectionHeight(topSectionElement.getBoundingClientRect().height);
        }
    });

    useEffect(() => {
        let timeoutId: number | undefined;

        if (!props.customer) {
            timeoutId = window.setTimeout(() => {
                setCustomer(null);
            }, 300);
        } else {
            setCustomer(props.customer);
        }

        return () => window.clearTimeout(timeoutId);
    }, [props.customer]);

    const handleAssignedUserChange = (userId: Customer['user_id'] | null) => {
        if (customer && userId !== null) {
            props.onCustomerChange({ email: customer.email, user_id: userId });
        }
    };

    return (
        <Sidebar
            className={className.base}
            position={SIDEBAR_POSITION.LEFT}
            isOpen={!!props.customer}
            onClose={props.onClose}
        >
            {customer && (
                <>
                    <div ref={setTopSectionRef} className={className['top-section']}>
                        <div className={className.header}>
                            <div className={className.name}>{customer.name}</div>
                            <div className={className['close-button']} onClick={props.onClose}>
                                <FontAwesomeIcon icon="times" />
                                <div className={className.label}>cerrar</div>
                            </div>
                        </div>
                        <div className={className.details}>
                            <SidebarDetail label="Teléfono">
                                <PhoneNumbers>{customer.phone_number}</PhoneNumbers>
                            </SidebarDetail>
                            <SidebarDetail label="Correo electrónico">{customer.email}</SidebarDetail>
                            <SidebarDetail label="Último recordatorio">
                                {customer.last_event_id ? (
                                    <Link
                                        onClick={() => {
                                            props.onClose();
                                            props.onLatestEventShow(customer.last_event_id);
                                        }}
                                    >
                                        <EventIdFormatter>{customer.last_event_id}</EventIdFormatter>
                                    </Link>
                                ) : (
                                    'Cliente sin recordatorios'
                                )}
                            </SidebarDetail>
                        </div>
                        <UserSelect
                            placeholder="Vendedora o vendedor"
                            value={customer.user_id}
                            isRequired
                            onChange={handleAssignedUserChange}
                        />
                    </div>
                    <OrderHistory customerEmail={customer.email} height={`calc(100% - ${topSectionHeight + 40}px)`} />
                </>
            )}
        </Sidebar>
    );
}
