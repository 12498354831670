import Button, { ButtonProps } from '../button/button';
import className from './submit-button.module.scss';
import cx from 'classnames';
import { ForwardedRef, forwardRef } from 'react';

function ForwardedSubmitButton({ children, ...props }: ButtonProps, ref: ForwardedRef<HTMLDivElement>) {
    return (
        <>
            <Button ref={ref} {...props} className={cx(className.base, props.className)}>
                {children}
            </Button>
            <input
                type="submit"
                style={{
                    height: 0,
                    opacity: 0,
                    position: 'absolute',
                }}
            />
        </>
    );
}

const SubmitButton = forwardRef(ForwardedSubmitButton);
export default SubmitButton;
