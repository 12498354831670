import { makeAutoObservable } from 'mobx';
import { User } from '../types/user';
import { LOCAL_STORAGE_KEY } from '../util/local-storage-key';
import { Http, HttpResponse, HTTP_STATUS_CODE } from './http';

interface LoginResponse {
    token: string;
    user: User;
}

export default class SessionStore {
    private http: Http;
    loggedInUserId: User['id'] | null;
    authToken: LoginResponse['token'] | null;
    isLoggingIn = false;
    isLoggingOut = false;

    constructor() {
        this.http = new Http(this);
        this.authToken = localStorage.getItem(LOCAL_STORAGE_KEY.AUTH_KEY);

        const storedLoggedInUserId = localStorage.getItem(LOCAL_STORAGE_KEY.LOGGED_IN_USER_ID);
        this.loggedInUserId = storedLoggedInUserId ? +storedLoggedInUserId : null;

        makeAutoObservable(this);
    }

    *login(email: User['email'], password: Required<User>['password']) {
        this.isLoggingIn = true;

        const response: HttpResponse<LoginResponse> = yield this.http.postApi<LoginResponse, User>('login', {
            email,
            password,
        });
        if (response.successful && response.data) {
            this.loggedInUserId = response.data.user.id;
            localStorage.setItem(LOCAL_STORAGE_KEY.LOGGED_IN_USER_ID, `${this.loggedInUserId}`);

            this.authToken = response.data.token;
            localStorage.setItem(LOCAL_STORAGE_KEY.AUTH_KEY, this.authToken);
        }

        switch (response.status) {
            case HTTP_STATUS_CODE.UNAUTHORIZED:
                window.contextMessageController.onFailShow('contraseña incorrecta');
                break;

            case HTTP_STATUS_CODE.FORBIDDEN:
                window.contextMessageController.onFailShow('correo incorrecto');
                break;
        }

        this.isLoggingIn = false;

        return response.successful;
    }

    *logout() {
        this.isLoggingOut = true;

        const response: HttpResponse<undefined> = yield this.http.postApi<undefined>('logout');
        if (response.successful) {
            localStorage.removeItem(LOCAL_STORAGE_KEY.LOGGED_IN_USER_ID);
            localStorage.removeItem(LOCAL_STORAGE_KEY.AUTH_KEY);
        }

        this.isLoggingOut = false;

        return response.successful;
    }
}
