import { makeAutoObservable } from 'mobx';
import { Customer, CustomerWithLatestDetails } from '../types/customer';
import { Store } from '../types/store';
import { Http, HttpResponse } from './http';
import SessionStore from './session';

export default class CustomerStore implements Store {
    private http: Http;
    customer: Customer | null = null;
    customers: (CustomerWithLatestDetails | null)[] = [];
    customerCount: number | null = null;
    isUpdating = false;
    isShowing = false;
    isIndexing = false;
    isCounting = false;

    constructor(sessionStore: SessionStore) {
        this.http = new Http(sessionStore);

        makeAutoObservable(this);
    }

    get path() {
        return 'customers';
    }

    reset = () => {
        this.customers = [];
        this.customerCount = null;
    };

    *index(stopIndex: number | null, filter?: string) {
        if (stopIndex === null) {
            this.isIndexing = true;

            const response: HttpResponse<CustomerWithLatestDetails[]> = yield this.http.getApi<
                CustomerWithLatestDetails[]
            >(
                this.path,
                filter
                    ? {
                          'email[like]': filter,
                          'name[like]': filter,
                      }
                    : undefined
            );

            if (response.successful && response.data) {
                this.customers = response.data;
                this.customerCount = response.data.length;
            }

            this.isIndexing = false;

            return response.successful;
        } else if (stopIndex >= this.customers.length) {
            const startIndex = this.customers.length;
            this.customers = this.customers.concat(new Array(stopIndex - startIndex + 1).fill(null));

            this.isIndexing = true;

            const response: HttpResponse<CustomerWithLatestDetails[]> = yield this.http.getApi<
                CustomerWithLatestDetails[]
            >(this.path, {
                startIndex,
                stopIndex,
            });

            if (response.successful && response.data) {
                const newCustomers = [...this.customers];
                response.data.forEach((customer, index) => {
                    newCustomers[index + startIndex] = customer;
                });
                this.customers = newCustomers;
            }

            this.isIndexing = false;

            return response.successful;
        } else {
            return false;
        }
    }

    setCustomer = (customer: Customer) => {
        this.customers = this.customers.map(currentCustomer =>
            currentCustomer && currentCustomer.email === customer.email
                ? { ...currentCustomer, ...customer }
                : currentCustomer
        );
    };

    *count() {
        this.isCounting = true;

        const response: HttpResponse<number> = yield this.http.getApi<number>(`${this.path}/count`);

        if (response.successful && response.data !== undefined) {
            this.customerCount = response.data;
        }

        this.isCounting = false;

        return response.successful;
    }

    *update({ email: customerEmail, ...customerParams }: Partial<Customer> & Pick<Customer, 'email'>) {
        this.isUpdating = true;

        const response: HttpResponse<Customer> = yield this.http.putApi<Customer>(
            `${this.path}/${customerEmail}`,
            customerParams
        );

        this.isUpdating = false;

        return response;
    }

    *show(customerEmail: Customer['email']) {
        this.isShowing = true;

        const response: HttpResponse<CustomerWithLatestDetails> = yield this.http.getApi<CustomerWithLatestDetails>(
            `${this.path}/${customerEmail}`
        );

        if (response.successful && response.data) {
            this.customer = response.data;
        }

        this.isShowing = false;

        return response;
    }
}
