import { createContext } from 'react';
import EventStore from './event';
import SessionStore from './session';
import UserStore from './user';
import OrderStore from './order';
import CustomerStore from './customer';
import BillingStateStore from './billing-state';

const sessionStore = new SessionStore();
const userStore = new UserStore(sessionStore);
const eventStore = new EventStore(sessionStore);
const orderStore = new OrderStore(sessionStore);
const customerStore = new CustomerStore(sessionStore);
const billingStateStore = new BillingStateStore(sessionStore);

export const storeContext = createContext({
    sessionStore,
    userStore,
    eventStore,
    orderStore,
    customerStore,
    billingStateStore,
});
