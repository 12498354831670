import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import className from './navbar.module.scss';
import cx from 'classnames';
import { useCallbackRef } from '../../util/use-callback-ref';
import UserImage from '../user-image/user-image';
import { useStore } from '../../stores';
import { observer } from 'mobx-react';

export enum NAVBAR_TAB {
    EVENTS = 'events',
    CUSTOMERS = 'customers',
    ORDERS = 'orders',
    USER = 'user',
}

interface NavbarProps {
    tab: NAVBAR_TAB;
    onTabChange: (tab: NAVBAR_TAB) => void;
}

export default observer(function Navbar(props: NavbarProps) {
    const { userStore, customerStore } = useStore();
    const { user, isShowing: isShowingUser } = userStore;
    const { isCounting: isCountingCustomers } = customerStore;

    const [selectedIndicatorLeftOffset, setSelectedIndicatorLeftOffset] = useState<number>(0);
    const [selectedIndicatorWidth, setSelectedIndicatorWidth] = useState<number>(0);
    const [selectedIndicatorHeight, setSelectedIndicatorHeight] = useState<number>(0);

    const [selectedActionRef, setSelectedActionRef] = useCallbackRef<HTMLDivElement>(selectedActionElement => {
        if (selectedActionElement) {
            const navbarElement = selectedActionElement.parentElement;
            if (navbarElement) {
                setSelectedIndicatorLeftOffset(
                    selectedActionElement.getBoundingClientRect().left - navbarElement.getBoundingClientRect().left
                );
                setSelectedIndicatorWidth(selectedActionElement.getBoundingClientRect().width);
                setSelectedIndicatorHeight(selectedActionElement.getBoundingClientRect().height);
            }
        }
    });

    useEffect(() => {
        if (!isShowingUser && !isCountingCustomers) {
            const selectedActionElement = selectedActionRef.current;
            if (selectedActionElement) {
                const navbarElement = selectedActionElement.parentElement;
                if (navbarElement) {
                    setSelectedIndicatorLeftOffset(
                        selectedActionElement.getBoundingClientRect().left - navbarElement.getBoundingClientRect().left
                    );
                    setSelectedIndicatorWidth(selectedActionElement.getBoundingClientRect().width);
                    setSelectedIndicatorHeight(selectedActionElement.getBoundingClientRect().height);
                }
            }
        }
    }, [user, isShowingUser, isCountingCustomers]);

    return (
        <div className={className.base}>
            <div
                className={className['selected-indicator']}
                style={{
                    left: selectedIndicatorLeftOffset - 1,
                    width: selectedIndicatorWidth,
                    height: selectedIndicatorHeight,
                }}
            />
            <div
                ref={props.tab === NAVBAR_TAB.EVENTS ? setSelectedActionRef : undefined}
                className={cx(className.action, {
                    [className.selected]: props.tab === NAVBAR_TAB.EVENTS,
                })}
                onClick={() => props.onTabChange(NAVBAR_TAB.EVENTS)}
            >
                <FontAwesomeIcon icon={['far', 'calendar']} />
                <div className={className.label}>Recordatorios</div>
            </div>
            <div
                ref={props.tab === NAVBAR_TAB.CUSTOMERS ? setSelectedActionRef : undefined}
                className={cx(className.action, {
                    [className.selected]: props.tab === NAVBAR_TAB.CUSTOMERS,
                })}
                onClick={() => props.onTabChange(NAVBAR_TAB.CUSTOMERS)}
            >
                <FontAwesomeIcon icon={['far', 'handshake']} />
                <div className={className.label}>Clientes</div>
            </div>
            <div
                ref={props.tab === NAVBAR_TAB.ORDERS ? setSelectedActionRef : undefined}
                className={cx(className.action, {
                    [className.selected]: props.tab === NAVBAR_TAB.ORDERS,
                })}
                onClick={() => props.onTabChange(NAVBAR_TAB.ORDERS)}
            >
                <FontAwesomeIcon icon={['far', 'list-alt']} />
                <div className={className.label}>Pedidos</div>
            </div>
            {user && (
                <div
                    ref={props.tab === NAVBAR_TAB.USER ? setSelectedActionRef : undefined}
                    className={cx(className.action, {
                        [className.selected]: props.tab === NAVBAR_TAB.USER,
                    })}
                    onClick={() => props.onTabChange(NAVBAR_TAB.USER)}
                >
                    <UserImage user={user} size={20} />
                    <div className={className.label}>Usuario</div>
                </div>
            )}
        </div>
    );
});
