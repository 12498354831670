import Select from '../select/select';
import { PET_TYPE } from '../../util/pet-type';
import { useMemo } from 'react';

interface PetTypeSelectProps {
    value: PET_TYPE;
    placeholder: string;
    isDisabled?: boolean;
    isRequired?: boolean;
    onChange: (petType: PET_TYPE | null) => void;
}

export default function PetTypeSelect(props: PetTypeSelectProps) {
    const formatPetTypeAsString = ({ id: petType }: { id: PET_TYPE }) => {
        switch (petType) {
            case PET_TYPE.CAT:
                return 'Gato';

            case PET_TYPE.DOG:
                return 'Perro';

            case PET_TYPE.DOG_AND_CAT:
                return 'Perro y gato';

            case PET_TYPE.OTHER:
            default:
                return 'Otro';
        }
    };

    const options = useMemo(
        () => Object.values(PET_TYPE).map(petType => ({ id: petType, value: petType })),
        [PET_TYPE]
    );

    return (
        <Select
            placeholder={props.placeholder}
            value={{ id: props.value, value: props.value }}
            options={options}
            formatOptionLabel={{
                asElement: formatPetTypeAsString,
                asString: formatPetTypeAsString,
            }}
            shouldBlur={false}
            shouldReset={false}
            isDisabled={props.isDisabled}
            isRequired={props.isRequired}
            onChange={petType => props.onChange(petType as PET_TYPE)}
        />
    );
}
