export enum OS {
    WINDOWS = 'Win',
    LINUX = 'Linux',
    MAC = 'Mac',
    UNIX = 'X11',
    IOS = 'iOS',
    ANDROID = 'Android',
}

export const getOS = () => {
    if (navigator.appVersion.includes(OS.WINDOWS)) {
        return OS.WINDOWS;
    } else if (/Android/.test(window.navigator.userAgent)) {
        return OS.ANDROID;
    } else if (navigator.appVersion.includes(OS.LINUX)) {
        return OS.LINUX;
    } else if (/(iPhone|iPad|iPod)/.test(window.navigator.userAgent)) {
        return OS.IOS;
    } else if (navigator.appVersion.includes(OS.MAC)) {
        return OS.MAC;
    } else if (navigator.appVersion.includes(OS.UNIX)) {
        return OS.UNIX;
    } else {
        return null;
    }
};
