import { Settings } from 'luxon';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './app';
import ContextMessage from './components/context-message/context-message';
import './index.scss';
import ResponsivenessProvider from './providers/responsiveness-provider';
import reportWebVitals from './reportWebVitals';
import { FontAwesomeLibrary } from './util/font-awesome-library';

FontAwesomeLibrary.initializeFontAwesomeLibrary();
Settings.defaultLocale = 'es-GT';

ReactDOM.render(
    <StrictMode>
        <ResponsivenessProvider>
            <Router>
                <ContextMessage />
                <App />
            </Router>
        </ResponsivenessProvider>
    </StrictMode>,
    document.getElementById('⚛')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
