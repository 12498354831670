import className from './user-select.module.scss';
import { useStore } from '../../stores';
import { User } from '../../types/user';
import Select from '../select/select';
import UserImage from '../user-image/user-image';

interface UserSelectProps {
    value: User['id'];
    placeholder: string;
    isDisabled?: boolean;
    isRequired?: boolean;
    onChange: (userId: User['id'] | null) => void;
}

export default function UserSelect(props: UserSelectProps) {
    const { userStore } = useStore();
    const { users, mappedUsers } = userStore;

    const formatAssignedUserAsElement = (user: User) => {
        return user.id >= 0 ? (
            <div className={className.user}>
                <UserImage user={user} size={24} />
                {user.name}
            </div>
        ) : (
            user.name
        );
    };

    const formatAssignedUserAsString = (user: User) => {
        return user.name;
    };

    return (
        <Select
            className={className.base}
            placeholder={props.placeholder}
            value={mappedUsers[props.value]}
            options={users}
            formatOptionLabel={{
                asElement: formatAssignedUserAsElement,
                asString: formatAssignedUserAsString,
            }}
            optionValueKey="id"
            shouldBlur={false}
            shouldReset={false}
            isDisabled={props.isDisabled}
            isRequired={props.isRequired}
            onChange={userId => props.onChange(userId as User['id'])}
        />
    );
}
