import { Validness } from '../types/validness';

export const getDefaultValidness = <T extends {}>(sampleObject: T): Validness<T> => {
    return Object.keys(sampleObject).reduce(
        (shippingAddressFormValidness, key) => ({
            ...shippingAddressFormValidness,
            [key]: false,
        }),
        {} as Validness<T>
    );
};
