import className from './no-results.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function NoResults() {
    return (
        <div className={className.base}>
            <FontAwesomeIcon icon="satellite" />
            <div className={className.text}>No hay resultados</div>
        </div>
    );
}
