export enum COLOR {
    GREEN_500 = 'var(--bp-green-500)',
    GREEN_600 = 'var(--bp-green-600)',
    GREEN_700 = 'var(--bp-green-700)',
    RED_300 = 'var(--bp-red-300)',
    BLUE_500 = 'var(--bp-blue-500)',
    ORANGE_500 = 'var(--bp-orange-500)',
    GRAY_100 = 'var(--bp-gray-100)',
    GRAY_50 = 'var(--bp-gray-50)',
}
