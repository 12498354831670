import className from './sidebar-detail.module.scss';
import { CSSProperties, ReactNode } from 'react';

interface SidebarDetailProps {
    label: string;
    children: ReactNode;
    style?: CSSProperties;
}

export default function SidebarDetail(props: SidebarDetailProps) {
    return (
        <div className={className.base} style={props.style}>
            <div className={className.label}>{props.label}</div>
            {props.children}
        </div>
    );
}
