import className from './date-picker.module.scss';
import cx from 'classnames';
import Button from '../button/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useState } from 'react';

interface DatePickerProps {
    value: string;
    isDisabledBefore?: string;
    onChange: (value: string) => void;
}

export default function DatePicker(props: DatePickerProps) {
    const [startOfMonthDateTime, setStartOfMonthDateTime] = useState<DateTime>(
        DateTime.fromISO(props.value).startOf('month')
    );

    const renderHeaderCells = () => {
        const headerCells: JSX.Element[] = [];

        const dateTime = DateTime.now().startOf('week');
        for (let index = 0; index < 7; index++) {
            headerCells.push(
                <div key={`header-cell-${index}`} className={className['header-cell']}>
                    {dateTime.plus({ days: index }).toFormat('ccc').substr(0, 3)}
                </div>
            );
        }

        return headerCells;
    };

    const renderBodyCells = () => {
        const bodyCells: JSX.Element[] = [];

        const dateTime = startOfMonthDateTime.startOf('week');
        const endOfMonthDateTime = startOfMonthDateTime.endOf('month').endOf('week');
        for (let index = 0; index < endOfMonthDateTime.diff(dateTime, 'days').days; index++) {
            const currentDateTime = dateTime.plus({ days: index });
            const isDisabled = props.isDisabledBefore
                ? currentDateTime < DateTime.fromISO(props.isDisabledBefore)
                : false;
            const isSelected = currentDateTime.hasSame(DateTime.fromISO(props.value), 'day');
            bodyCells.push(
                <div
                    key={`body-cell-${index}`}
                    className={cx(className['body-cell'], {
                        [className.outside]: !currentDateTime.hasSame(startOfMonthDateTime, 'month'),
                        [className.today]: currentDateTime.hasSame(DateTime.now(), 'day'),
                        [className.selected]: isSelected,
                        [className.disabled]: isDisabled,
                    })}
                    onClick={!isDisabled && !isSelected ? () => props.onChange(currentDateTime.toISODate()) : undefined}
                >
                    {currentDateTime.day}
                </div>
            );
        }

        return bodyCells;
    };

    const handlePreviousButtonClick = () => {
        setStartOfMonthDateTime(startOfMonthDateTime => startOfMonthDateTime.minus({ month: 1 }));
    };

    const handleNextButtonClick = () => {
        setStartOfMonthDateTime(startOfMonthDateTime => startOfMonthDateTime.plus({ month: 1 }));
    };

    return (
        <div className={className.base}>
            <div className={className.header}>
                <Button
                    onClick={handlePreviousButtonClick}
                    isDisabled={
                        props.isDisabledBefore
                            ? startOfMonthDateTime.hasSame(DateTime.fromISO(props.isDisabledBefore), 'month')
                            : false
                    }
                    isSquared={true}
                >
                    <FontAwesomeIcon icon="chevron-left" />
                </Button>
                <div className={className.title}>{`${startOfMonthDateTime.toFormat(
                    'MMMM'
                )} ${startOfMonthDateTime.toFormat('yyyy')}`}</div>
                <Button onClick={handleNextButtonClick} isSquared={true}>
                    <FontAwesomeIcon icon="chevron-right" />
                </Button>
            </div>
            <div className={className.body}>
                {renderHeaderCells()}
                {renderBodyCells()}
            </div>
        </div>
    );
}
