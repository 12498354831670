import ReactSelect from 'react-select';
import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { getOS, OS } from '../../util/get-os';
import className from './select.module.scss';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface Option {
    label: string;
    value: string | number;
}

interface SelectProps<T> {
    placeholder: string;
    options: T[];
    shouldReset: boolean;
    shouldBlur: boolean;
    value?: T | null;
    isRequired?: boolean;
    isDisabled?: boolean;
    className?: string;
    optionValueKey?: keyof T;
    formatOptionLabel?: {
        asElement: (option: T) => JSX.Element | string;
        asString: (option: T) => string;
    };
    onChange: (value: Option['value'] | T[keyof T] | null) => void;
}

export default function Select<T extends {} = Option>(props: SelectProps<T>) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isBlurred, setIsBlurred] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    useEffect(() => {
        if (props.shouldReset) {
            setIsBlurred(false);
        }
    }, [props.shouldReset]);

    useEffect(() => {
        if (props.shouldBlur) {
            setIsBlurred(true);
        }
    }, [props.shouldBlur]);

    const isMobile = useMemo(() => {
        const os = getOS();

        return os === OS.IOS || os === OS.ANDROID;
    }, []);

    const isGenericOption = (option: T | Option): option is Option => Object.keys(option).includes('value');

    const getOptionValue = (option: T) => {
        return props.optionValueKey ? option[props.optionValueKey] : isGenericOption(option) ? option.value : null;
    };

    const handleChange = useCallback(
        (option: T | null) => {
            props.onChange(
                option
                    ? props.optionValueKey
                        ? option[props.optionValueKey]
                        : isGenericOption(option)
                        ? option.value
                        : null
                    : null
            );
        },
        [props.onChange]
    );

    const handleNativeChange = useCallback(
        (value: string | null) => {
            props.onChange(value);
        },
        [props.onChange]
    );

    const handleMenuOpen = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);

    const handleMenuClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const handleBlur = () => {
        setIsBlurred(true);
        setIsFocused(false);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    return (
        <div
            className={cx(className.base, props.className, {
                [className.empty]: !props.value,
                [className.open]: isOpen || (isMobile && isFocused),
                [className.focused]: isFocused,
                [className.disabled]: props.isDisabled,
            })}
            onClick={handleClick}
        >
            <div className={className.border} />
            {isMobile ? (
                <>
                    <select
                        value={props.value ? `${getOptionValue(props.value)}` : undefined}
                        className={className.input}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        disabled={props.isDisabled}
                        onChange={event => handleNativeChange(event.currentTarget.value)}
                    >
                        {props.options.map(option => {
                            const value = getOptionValue(option);

                            const label = isGenericOption(option)
                                ? option.label
                                : props.formatOptionLabel
                                ? props.formatOptionLabel.asString(option)
                                : null;

                            return (
                                <option key={`${value}`} value={`${value}`}>
                                    {label}
                                </option>
                            );
                        })}
                    </select>
                    <div className={className.label}>
                        {props.value
                            ? isGenericOption(props.value)
                                ? props.value.label
                                : props.formatOptionLabel
                                ? props.formatOptionLabel.asElement(props.value)
                                : null
                            : null}
                    </div>
                </>
            ) : (
                <ReactSelect<T>
                    className={className.input}
                    value={props.value}
                    placeholder=""
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    options={props.options}
                    isClearable={!props.isRequired}
                    isDisabled={props.isDisabled}
                    menuPlacement="top"
                    menuShouldBlockScroll={true}
                    styles={{
                        control: provided => ({
                            ...provided,
                            border: '0',
                            boxShadow: 'none',
                            width: '100%',
                            minHeight: 'initial',
                            padding: '14px 16px 0',
                            borderRadius: 'var(--bp-border-radius)',
                            backgroundColor: props.isDisabled ? 'var(--bp-gray-25)' : undefined,
                        }),
                        indicatorsContainer: () => ({
                            display: 'none',
                        }),
                        valueContainer: provided => ({
                            ...provided,
                            padding: 0,
                        }),
                        singleValue: provided => ({
                            ...provided,
                            margin: 0,
                            color: props.isDisabled ? 'var(--bp-gray-400)' : undefined,
                        }),
                        menu: provided => ({
                            ...provided,
                            zIndex: 1000,
                        }),
                    }}
                    formatOptionLabel={
                        props.formatOptionLabel ? option => props.formatOptionLabel!.asElement(option) : undefined
                    }
                    getOptionValue={props.optionValueKey ? option => `${option[props.optionValueKey!]}` : undefined}
                    onMenuOpen={handleMenuOpen}
                    onMenuClose={handleMenuClose}
                />
            )}
            <div className={className.placeholder}>
                {props.placeholder}
                {props.isRequired && <div className={className['required-indicator']}>*</div>}
            </div>
            {!props.isRequired && (
                <div
                    className={cx(className.clear, { [className.shown]: props.value })}
                    onClick={event => {
                        event.stopPropagation();
                        if (isMobile) {
                            handleNativeChange(null);
                        } else {
                            handleChange(null);
                        }
                    }}
                >
                    <FontAwesomeIcon icon="times" />
                </div>
            )}
            <div className={className['open-indicator']}>
                <FontAwesomeIcon icon="chevron-down" />
            </div>
        </div>
    );
}
