import { isEqual } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dirtiness } from '../types/dirtiness';
import { FormChangeHandler, FormValidnessChangeHandler } from '../types/form';
import { Validness } from '../types/validness';
import { getDefaultDirtiness } from './get-default-dirtiness';
import { getDefaultValidness } from './get-default-validness';
import { isDirtinessDirty } from './is-dirtiness-dirty';
import { isValidnessInvalid } from './is-validness-invalid';

export const useForm = <T extends {}>(
    defaultForm: T,
    defaultShowForm = false
): [
    T,
    boolean,
    boolean,
    FormChangeHandler<T>,
    FormValidnessChangeHandler<T>,
    (onFormReset: () => void) => void,
    boolean,
    Dispatch<SetStateAction<boolean>>,
    Dispatch<SetStateAction<T>>,
    Dispatch<SetStateAction<Validness<T>>>
] => {
    const handleFormReset = useRef<() => void>(() => {});
    const [form, setForm] = useState<T>(defaultForm);
    const [initialForm, setInitialForm] = useState<T>(defaultForm);
    const [formValidness, setFormValidness] = useState<Validness<T>>(getDefaultValidness(defaultForm));
    const [formDirtiness, setFormDirtiness] = useState<Dirtiness<T>>(getDefaultDirtiness(defaultForm));
    const [isFormShown, setIsFormShown] = useState<boolean>(defaultShowForm);

    useEffect(() => {
        if (!isFormShown) {
            handleFormReset.current();
        }
    }, [isFormShown]);

    const handleFormChange = useCallback(
        <U extends keyof T>(keys: U | U[], values: T[U] | T[U][]) => {
            setForm(form => {
                const newForm = { ...form };

                if (Array.isArray(keys) && Array.isArray(values) && keys.length === values.length) {
                    keys.forEach((key, index) => {
                        newForm[key] = values[index];
                        setFormDirtiness(formDirtiness => ({
                            ...formDirtiness,
                            [key]: !isEqual(initialForm[key], values[index]),
                        }));
                    });
                } else if (!Array.isArray(keys) && !Array.isArray(values)) {
                    newForm[keys] = values;
                    setFormDirtiness(formDirtiness => ({
                        ...formDirtiness,
                        [keys]: !isEqual(initialForm[keys], values),
                    }));
                } else {
                    throw new TypeError('Keys and values should be compatible.');
                }

                return newForm;
            });
        },
        [initialForm]
    );

    const handleFormValidnessChange = useCallback((key: keyof T, isInvalid: boolean) => {
        setFormValidness(formValidness => ({
            ...formValidness,
            [key]: isInvalid,
        }));
    }, []);

    const isFormInvalid = useMemo(() => isValidnessInvalid(formValidness), [formValidness]);

    const isFormDirty = useMemo(() => isDirtinessDirty(formDirtiness), [formDirtiness]);

    const setFormResetHandler = (onFormReset: () => void) => {
        handleFormReset.current = onFormReset;
    };

    const handleSetForm: Dispatch<SetStateAction<T>> = useCallback(form => {
        setForm(form);
        setInitialForm(form);
    }, []);

    return [
        form,
        isFormInvalid,
        isFormDirty,
        handleFormChange,
        handleFormValidnessChange,
        setFormResetHandler,
        isFormShown,
        setIsFormShown,
        handleSetForm,
        setFormValidness,
    ];
};
