import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useStore } from '../../stores';
import EventSidebar from '../event-sidebar/event-sidebar';
import Footer from '../footer/footer';
import Navbar, { NAVBAR_TAB } from '../navbar/navbar';
import className from './home.module.scss';
import cx from 'classnames';
import { Event } from '../../types/event';
import UserRoute from '../routes/user/user';
import EventsRoute from '../routes/events/events';
import CustomersRoute, { CustomersRouteSearchForm } from '../routes/customers/customers';
import { Customer, CustomerWithLatestDetails } from '../../types/customer';
import CustomerSidebar from '../customer-sidebar/customer-sidebar';
import OrderSidebar from '../order-sidebar/order-sidebar';
import { SIDEBAR_POSITION } from '../sidebar/sidebar';
import { DateTime } from 'luxon';
import OrdersRoute, { OrdersRouteSearchForm } from '../routes/orders/orders';
import { OrderWithDetails } from '../../types/order';

export default observer(function Home() {
    const { userStore, sessionStore, eventStore, customerStore, billingStateStore } = useStore();
    const { loggedInUserId } = sessionStore;

    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();

    const [navbarTab, setNavbarTab] = useState<NAVBAR_TAB>(NAVBAR_TAB.EVENTS);
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerWithLatestDetails | null>(null);
    const [selectedOrder, setSelectedOrder] = useState<OrderWithDetails | null>(null);
    const [eventSidebarPosition, setEventSidebarPosition] = useState<SIDEBAR_POSITION>(SIDEBAR_POSITION.LEFT);
    const [startOfWeekDateTime, setStartOfWeekDateTime] = useState<DateTime>(DateTime.now().minus({ days: 3 }));
    const [customersRouteSearchForm, setCustomersRouteSearchForm] = useState<CustomersRouteSearchForm>({
        customer: null,
    });
    const [ordersRouteSearchForm, setOrdersRouteSearchForm] = useState<OrdersRouteSearchForm>({
        id: null,
        productName: null,
        billingStateId: null,
        status: null,
    });

    useEffect(() => {
        if (sessionStore.loggedInUserId !== null) {
            flowResult(userStore.show(sessionStore.loggedInUserId)).then(successful => {
                if (successful) {
                    userStore.index();
                    billingStateStore.index();
                }
            });
        }
    }, [loggedInUserId]);

    const isNavbarTab = (navbarTab: string): navbarTab is NAVBAR_TAB => {
        return (Object.values(NAVBAR_TAB) as string[]).includes(navbarTab);
    };

    useEffect(() => {
        const navbarTab = location.pathname.replace(`${match.path}/`, '');
        if (isNavbarTab(navbarTab)) {
            setNavbarTab(navbarTab);
        } else {
            history.push(`${match.path}/${NAVBAR_TAB.EVENTS}`);
            setNavbarTab(NAVBAR_TAB.EVENTS);
        }
    }, [location]);

    const handleNavbarTabChange = (tab: NAVBAR_TAB) => {
        history.push(`${match.path}/${tab}`);
        setNavbarTab(tab);
    };

    const handleEventClick = (event: Event) => {
        const customAtDateTime = DateTime.fromISO(event.custom_at);
        const baseDayIndex = startOfWeekDateTime.weekday;
        const middleDayIndex = (baseDayIndex - 7 + (baseDayIndex - 1)) / 2;
        const dayIndex =
            customAtDateTime.weekday < baseDayIndex ? customAtDateTime.weekday : customAtDateTime.weekday - 7;
        setEventSidebarPosition(dayIndex <= middleDayIndex ? SIDEBAR_POSITION.RIGHT : SIDEBAR_POSITION.LEFT);
        setSelectedEvent(event);
    };

    const handleEventShow = (eventId: Event['id']) => {
        flowResult(eventStore.show(eventId)).then(({ data, successful }) => {
            if (successful && data) {
                if (navbarTab !== NAVBAR_TAB.EVENTS) {
                    handleNavbarTabChange(NAVBAR_TAB.EVENTS);
                }
                handleEventClick({ ...data, latest: true });
            }
        });
    };

    const handleCustomerShow = (customerEmail: Customer['email']) => {
        flowResult(customerStore.show(customerEmail)).then(({ data, successful }) => {
            if (successful && data) {
                handleCustomerClick(data);
            }
        });
    };

    const handleCustomerClick = (customer: CustomerWithLatestDetails) => {
        setSelectedCustomer(customer);
    };

    const handleOrderClick = (order: OrderWithDetails) => {
        setSelectedOrder(order);
    };

    const handleEventDeselect = () => {
        setSelectedEvent(null);
    };

    const handleCustomerDeselect = () => {
        setSelectedCustomer(null);
    };

    const handleOrderDeselect = () => {
        setSelectedOrder(null);
    };

    const handleCustomerChange = (customer: Partial<Customer> & Pick<Customer, 'email'>) => {
        flowResult(customerStore.update(customer)).then(({ data, successful }) => {
            if (successful && data) {
                if (selectedEvent) {
                    setSelectedEvent(selectedEvent => (selectedEvent ? { ...selectedEvent, customer: data } : null));
                    eventStore.setCustomer(data);
                } else if (selectedCustomer) {
                    setSelectedCustomer(selectedCustomer =>
                        selectedCustomer ? { ...selectedCustomer, ...data } : null
                    );
                    customerStore.setCustomer(data);
                }
            }
        });
    };

    const handleEventChange = (event: Partial<Event> & Pick<Event, 'id'>) => {
        flowResult(eventStore.update(event)).then(successful => {
            if (successful) {
                setSelectedEvent(selectedEvent => (selectedEvent ? { ...selectedEvent, ...event } : null));
            }
        });
    };

    return (
        <>
            <div
                className={cx(className.base, {
                    [className.blurred]: selectedEvent !== null || selectedCustomer !== null || selectedOrder !== null,
                })}
            >
                <div className={className.body}>
                    <Switch>
                        <Route path={`${match.path}/${NAVBAR_TAB.EVENTS}`}>
                            <EventsRoute
                                startOfWeekDateTime={startOfWeekDateTime}
                                selectedEvent={selectedEvent}
                                onStartOfWeekDateTimeChange={setStartOfWeekDateTime}
                                onEventClick={handleEventClick}
                            />
                        </Route>
                        <Route path={`${match.path}/${NAVBAR_TAB.CUSTOMERS}`}>
                            <CustomersRoute
                                searchForm={customersRouteSearchForm}
                                onCustomerClick={handleCustomerClick}
                                onLatestEventShow={handleEventShow}
                                onSearchFormChange={setCustomersRouteSearchForm}
                            />
                        </Route>
                        <Route path={`${match.path}/${NAVBAR_TAB.ORDERS}`}>
                            <OrdersRoute
                                searchForm={ordersRouteSearchForm}
                                onOrderClick={handleOrderClick}
                                onEventShow={handleEventShow}
                                onSearchFormChange={setOrdersRouteSearchForm}
                            />
                        </Route>
                        <Route path={`${match.path}/${NAVBAR_TAB.USER}`}>
                            <UserRoute />
                        </Route>
                    </Switch>
                </div>
                <Navbar tab={navbarTab} onTabChange={handleNavbarTabChange} />
                <Footer />
                <div
                    className={className.mask}
                    onClick={() => {
                        handleEventDeselect();
                        handleCustomerDeselect();
                        handleOrderDeselect();
                    }}
                />
            </div>
            <EventSidebar
                event={selectedEvent}
                position={eventSidebarPosition}
                onCustomerChange={handleCustomerChange}
                onEventChange={handleEventChange}
                onLatestEventShow={handleEventShow}
                onClose={handleEventDeselect}
            />
            <CustomerSidebar
                customer={selectedCustomer}
                onCustomerChange={handleCustomerChange}
                onLatestEventShow={handleEventShow}
                onClose={handleCustomerDeselect}
            />
            <OrderSidebar
                order={selectedOrder}
                onEventShow={handleEventShow}
                onCustomerShow={handleCustomerShow}
                onClose={handleOrderDeselect}
            />
        </>
    );
});
