import TextInput, { TextInputProps } from '../text-input/text-input';
import { useEffect, useState } from 'react';
import { EMAIL_REGEX } from '../../util/regex';

type EmailInputProps<T, U extends keyof T, IsRequired extends boolean> = Omit<
    TextInputProps<T, U, IsRequired>,
    'placeholder'
>;

export default function EmailInput<T extends {}, U extends keyof T, IsRequired extends boolean = false>(
    props: EmailInputProps<T, U, IsRequired>
) {
    const value = props.form[props.valueKey] || '';
    if (typeof value !== 'string') {
        throw new Error(`Type of ${props.valueKey} must be string`);
    }

    const [isInvalid, setIsInvalid] = useState<boolean>(false);

    useEffect(() => {
        if (props.onValidnessChange) {
            props.onValidnessChange(props.valueKey, isInvalid);
        }
    }, [isInvalid]);

    useEffect(() => {
        let newIsInvalid = true;

        if (EMAIL_REGEX.test(value)) {
            newIsInvalid = false;
        }

        setIsInvalid(newIsInvalid);
    }, [value]);

    const { onValidnessChange, ...textInputProps } = props;

    return (
        <TextInput
            {...textInputProps}
            placeholder={<>Correo</>}
            isInvalid={{
                value: isInvalid,
                label: <>El correo es inválido</>,
            }}
        />
    );
}
