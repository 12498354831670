import { makeAutoObservable } from 'mobx';
import { OrdersRouteSearchForm } from '../components/routes/orders/orders';
import { Customer } from '../types/customer';
import { Order, OrderWithDetails } from '../types/order';
import { Store } from '../types/store';
import { Http, HttpResponse } from './http';
import SessionStore from './session';

export default class OrderStore implements Store {
    private http: Http;
    ordersByCustomer: Order[] = [];
    orders: (OrderWithDetails | null)[] = [];
    orderCount: number | null = null;
    isIndexing = false;
    isIndexingByCustomer = false;
    isCounting = false;

    constructor(sessionStore: SessionStore) {
        this.http = new Http(sessionStore);

        makeAutoObservable(this);
    }

    buildPathByCustomer = (orderEmail: Customer['email']) => {
        return `customers/${orderEmail}/orders`;
    };

    get path() {
        return 'orders';
    }

    reset = () => {
        this.orders = [];
        this.orderCount = null;
    };

    *index(stopIndex: number | null, filters?: OrdersRouteSearchForm) {
        if (stopIndex === null) {
            this.isIndexing = true;

            const response: HttpResponse<OrderWithDetails[]> = yield this.http.getApi<OrderWithDetails[]>(
                this.path,
                filters
                    ? {
                          'id[like]': filters.id || undefined,
                          'product_name[ilike]': filters.productName ? filters.productName.trim() : undefined,
                          'billing_state_id[=]': filters.billingStateId || undefined,
                          'status[=]': filters.status || undefined,
                      }
                    : undefined
            );

            if (response.successful && response.data) {
                this.orders = response.data;
                this.orderCount = response.data.length;
            }

            this.isIndexing = false;

            return response.successful;
        } else if (stopIndex >= this.orders.length) {
            const startIndex = this.orders.length;
            this.orders = this.orders.concat(new Array(stopIndex - startIndex + 1).fill(null));

            this.isIndexing = true;

            const response: HttpResponse<OrderWithDetails[]> = yield this.http.getApi<OrderWithDetails[]>(this.path, {
                startIndex,
                stopIndex,
            });

            if (response.successful && response.data) {
                const newCustomers = [...this.orders];
                response.data.forEach((order, index) => {
                    newCustomers[index + startIndex] = order;
                });
                this.orders = newCustomers;
            }

            this.isIndexing = false;

            return response.successful;
        } else {
            return false;
        }
    }

    *indexByCustomer(orderEmail: Customer['email']) {
        this.isIndexingByCustomer = true;

        const response: HttpResponse<Order[]> = yield this.http.getApi<HttpResponse<Order[]>>(
            this.buildPathByCustomer(orderEmail)
        );

        if (response.successful && response.data) {
            this.ordersByCustomer = response.data;
        }

        this.isIndexingByCustomer = false;

        return response.successful;
    }

    *count() {
        this.isCounting = true;

        const response: HttpResponse<number> = yield this.http.getApi<number>(`${this.path}/count`);

        if (response.successful && response.data !== undefined) {
            this.orderCount = response.data;
        }

        this.isCounting = false;

        return response.successful;
    }
}
