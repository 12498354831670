import { useMemo } from 'react';

interface SearchResultProps {
    children: string;
    searchText: string | null;
}

export default function SearchResult(props: SearchResultProps) {
    const searchText = useMemo(() => props.searchText?.trim(), [props.searchText]);

    const indexOfSearchText = searchText ? props.children.toLowerCase().indexOf(searchText.toLowerCase()) : -1;

    return (
        <>
            {searchText && indexOfSearchText > -1 ? (
                <>
                    {props.children.substring(0, indexOfSearchText)}
                    <b>{props.children.substr(indexOfSearchText, searchText.length)}</b>
                    {props.children.substring(indexOfSearchText + searchText.length)}
                </>
            ) : (
                props.children
            )}
        </>
    );
}
