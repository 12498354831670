import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Redirect, Route, RouteProps, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Home from './components/home/home';
import Login from './components/login/login';
import { useStore } from './stores';
import { ROUTE } from './util/route';
import { History } from 'history';

declare global {
    interface Window {
        historyController: History;
    }
}

export default observer(function App() {
    const { sessionStore } = useStore();
    const { loggedInUserId } = sessionStore;

    const history = useHistory();
    const { isExact: isInLoginRoute } = useRouteMatch(ROUTE.LOGIN) || { isExact: false };

    useEffect(() => {
        window.historyController = history;
    }, []);

    useEffect(() => {
        if (loggedInUserId !== null && isInLoginRoute) {
            history.push(ROUTE.HOME);
        }
    }, [loggedInUserId]);

    const RootRoute = ({ children, ...props }: RouteProps) => {
        return (
            <Route
                {...props}
                render={({ location }) => (
                    <Redirect
                        to={{
                            pathname: ROUTE.HOME,
                            state: { from: location },
                        }}
                    />
                )}
            />
        );
    };

    return (
        <Switch>
            {loggedInUserId !== null || isInLoginRoute ? (
                <>
                    <RootRoute exact={true} path={ROUTE.ROOT} />
                    <Route path={ROUTE.LOGIN}>
                        <Login />
                    </Route>
                    <Route path={ROUTE.HOME}>
                        <Home />
                    </Route>
                </>
            ) : (
                <Redirect to={ROUTE.LOGIN} />
            )}
        </Switch>
    );
});
