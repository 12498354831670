import { Mapped } from '../types/mapped';

export const getMappedObjects = <T extends { id: string | number }>(objects: T[]) => {
    return objects.reduce<Mapped<T>>(
        (mappedObjects, object) => ({
            ...mappedObjects,
            [`${object.id}`]: object,
        }),
        {}
    );
};
