import { Dirtiness } from '../types/dirtiness';

export const getDefaultDirtiness = <T extends {}>(sampleObject: T): Dirtiness<T> => {
    return Object.keys(sampleObject).reduce(
        (shippingAddressFormDirtiness, key) => ({
            ...shippingAddressFormDirtiness,
            [key]: false,
        }),
        {} as Dirtiness<T>
    );
};
