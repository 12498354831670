import { ForwardedRef, forwardRef, ReactNode, useEffect, useState } from 'react';
import className from './button.module.scss';
import cx from 'classnames';
import { useCallbackRef } from '../../util/use-callback-ref';
import { COLOR } from '../../util/color';
import Spinner from '../spinner/spinner';

export interface ButtonProps {
    children: ReactNode;
    color?: COLOR;
    isDisabled?: boolean;
    isLoading?: boolean;
    isSquared?: boolean;
    className?: string;
    onClick?: () => void;
}

function ForwardedButton(props: ButtonProps, ref: ForwardedRef<HTMLDivElement>) {
    const [contentWidth, setContentWidth] = useState<number>(0);
    const [contentRef, setContentRef] = useCallbackRef<HTMLDivElement>(contentElement => {
        if (contentElement) {
            setContentWidth(contentElement.getBoundingClientRect().width);
        }
    });

    useEffect(() => {
        const contentElement = contentRef.current;
        if (contentElement) {
            setContentWidth(contentElement.getBoundingClientRect().width);
        }
    }, [props.children]);

    return (
        <div
            ref={ref}
            className={cx(className.base, props.className, {
                [className.disabled]: props.isDisabled,
                [className.loading]: props.isLoading,
                [className.squared]: props.isSquared,
            })}
            style={{ backgroundColor: props.color, color: props.color ? 'var(--bp-white)' : undefined }}
            onClick={!props.isDisabled && !props.isLoading ? props.onClick : undefined}
        >
            <div ref={setContentRef} className={className.content}>
                {props.children}
            </div>
            <div className={className['loading-indicator']} style={{ left: `calc(50% - ${contentWidth / 2}px)` }}>
                <Spinner className={className.spinner} />
            </div>
        </div>
    );
}

const Button = forwardRef(ForwardedButton);
export default Button;
