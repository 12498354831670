import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { FormChangeHandler, FormValidnessChangeHandler } from '../../types/form';
import { getOS, OS } from '../../util/get-os';
import className from './form.module.scss';
import cx from 'classnames';
import { useDebounce } from '../../util/use-debounce';
import { usePrevious } from '../../util/use-previous';
import { isEqual } from 'lodash';

interface FormProps<T> {
    form: T;
    isInvalid: boolean;
    isShown: boolean;
    children: (inputProps: {
        form: T;
        shouldReset: boolean;
        shouldBlur: boolean;
        onChange: FormChangeHandler<T>;
        onValidnessChange: FormValidnessChangeHandler<T>;
    }) => ReactNode;
    submitButton?: {
        isDisabled: boolean;
        isLoading: boolean;
        element: HTMLDivElement | null;
        addOnSubmitEventListener?: boolean;
    };
    focusOnShow?: boolean;
    className?: string;
    autoComplete?: string;
    onChange: FormChangeHandler<T>;
    onValidnessChange: FormValidnessChangeHandler<T>;
    onSubmit?: () => void;
    onAutoSubmit?: () => void;
    setFormResetHandler: (onFormReset: () => void) => void;
}

export default function Form<T extends {}>(props: FormProps<T>) {
    const formRef = useRef<HTMLFormElement>(null);
    const [shouldReset, setShouldReset] = useState<boolean>(false);
    const [shouldBlur, setShouldBlur] = useState<boolean>(false);
    const previousForm = usePrevious<T>(props.form);

    useDebounce(
        () => {
            if (previousForm && !isEqual(props.form, previousForm) && props.onAutoSubmit) {
                props.onAutoSubmit();
            }
        },
        500,
        [props.form]
    );

    useEffect(() => {
        if (props.focusOnShow !== false && formRef.current && props.isShown) {
            const firstInputElement = formRef.current.querySelector<HTMLInputElement>('input');
            if (firstInputElement) {
                const os = getOS();
                if (os !== OS.IOS && os !== OS.ANDROID) {
                    firstInputElement.focus({ preventScroll: true });
                }
            }
        }
    }, [props.isShown]);

    const propsRef = useRef<FormProps<T>>(props);
    useEffect(() => {
        propsRef.current = props;
    }, [props]);

    useEffect(() => {
        if (shouldBlur) {
            setShouldBlur(false);
        }
    }, [shouldBlur]);

    const handleSubmit = useCallback(
        (addEventListener: boolean) => {
            if (
                propsRef.current.isInvalid &&
                propsRef.current.isShown &&
                (!propsRef.current.submitButton || !propsRef.current.submitButton.isLoading)
            ) {
                setShouldBlur(true);
            } else if (
                (!propsRef.current.submitButton ||
                    (!propsRef.current.submitButton.isDisabled && !propsRef.current.submitButton.isLoading)) &&
                addEventListener &&
                props &&
                propsRef.current.onSubmit
            ) {
                propsRef.current.onSubmit();
            }
        },
        [
            propsRef.current.isInvalid,
            propsRef.current.onSubmit,
            propsRef.current.isShown,
            propsRef.current.submitButton?.isDisabled,
            propsRef.current.submitButton?.isLoading,
            propsRef.current.submitButton?.addOnSubmitEventListener,
        ]
    );

    const handleSubmitEventListener = useCallback(
        () =>
            handleSubmit(
                !!propsRef.current.submitButton && propsRef.current.submitButton.addOnSubmitEventListener !== false
            ),
        [handleSubmit, propsRef.current.submitButton?.addOnSubmitEventListener]
    );

    useEffect(() => {
        if (props.submitButton) {
            const submitButtonElement = props.submitButton.element;
            if (submitButtonElement) {
                submitButtonElement.addEventListener('click', handleSubmitEventListener);

                return () => submitButtonElement.removeEventListener('click', handleSubmitEventListener);
            }
        }
    }, [props.submitButton?.element]);

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (formRef.current && formRef.current.contains(document.activeElement)) {
                event.stopPropagation();
                event.stopImmediatePropagation();
                let isPressingSpecialKey = false;
                switch (getOS()) {
                    case OS.MAC:
                        isPressingSpecialKey = event.metaKey;
                        break;
                    default:
                        isPressingSpecialKey = event.ctrlKey;
                        break;
                }
                if (isPressingSpecialKey && event.code === 'Enter') {
                    handleSubmit(true);
                }
            }
        },
        [handleSubmit]
    );

    useEffect(() => {
        addEventListener('keydown', handleKeyDown);

        return () => removeEventListener('keydown', handleKeyDown);
    }, []);

    useEffect(() => {
        if (shouldReset) {
            setShouldReset(false);
        }
    }, [shouldReset]);

    const handleReset = useCallback(() => {
        setShouldReset(true);
    }, []);

    useEffect(() => {
        props.setFormResetHandler(handleReset);
    }, [handleReset]);

    return (
        <form
            ref={formRef}
            className={cx(className.base, props.className)}
            autoComplete={props.autoComplete || 'on'}
            onSubmit={props.onSubmit}
            action=""
        >
            {props.children({
                form: props.form,
                shouldReset,
                shouldBlur,
                onChange: props.onChange,
                onValidnessChange: props.onValidnessChange,
            })}
        </form>
    );
}
