import { DateTime } from 'luxon';
import { makeAutoObservable } from 'mobx';
import { Customer } from '../types/customer';
import { Event } from '../types/event';
import { Store } from '../types/store';
import { Http, HttpResponse } from './http';
import SessionStore from './session';

export default class EventStore implements Store {
    private http: Http;
    events: Event[];
    isIndexing = false;
    isUpdating = false;
    isShowing = false;
    isShowingLatestId = false;

    constructor(sessionStore: SessionStore) {
        this.http = new Http(sessionStore);
        this.events = [];

        makeAutoObservable(this);
    }

    get path() {
        return 'events';
    }

    *index(startDateTime: DateTime, endDateTime: DateTime) {
        this.isIndexing = true;

        const response: HttpResponse<Event[]> = yield this.http.getApi<Event[]>(this.path, {
            start_date: startDateTime.toISODate(),
            end_date: endDateTime.toISODate(),
        });

        if (response.successful && response.data) {
            this.events = response.data;
        }

        this.isIndexing = false;

        return response.successful;
    }

    setCustomer = (customer: Customer) => {
        this.events = this.events.map(event =>
            event.customer.email === customer.email ? { ...event, customer: customer } : event
        );
    };

    *update({ id: eventId, ...eventParams }: Partial<Omit<Event, 'latest'>> & Pick<Event, 'id'>) {
        this.isUpdating = true;

        const response: HttpResponse<Event> = yield this.http.putApi<Event>(`${this.path}/${eventId}`, eventParams);

        if (response.successful && response.data) {
            this.events = this.events.map(event =>
                event.id === eventId ? { ...event, ...eventParams, ...response.data } : event
            );
        }

        this.isUpdating = false;

        return response.successful;
    }

    *show(eventId: Event['id']) {
        this.isShowing = true;

        const response: HttpResponse<Omit<Event, 'latest'>> = yield this.http.getApi<Omit<Event, 'latest'>>(
            `${this.path}/${eventId}`
        );

        this.isShowing = false;

        return response;
    }

    *showLatestId(eventId: Event['id']) {
        this.isShowingLatestId = true;

        const response: HttpResponse<Event['id']> = yield this.http.getApi<Event['id']>(
            `${this.path}/${eventId}/latest-id`
        );

        this.isShowingLatestId = false;

        return response;
    }
}
