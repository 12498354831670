import className from './order-sidebar.module.scss';
import Sidebar, { SIDEBAR_POSITION } from '../sidebar/sidebar';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SidebarDetail from '../sidebar/sidebar-detail';
import Link from '../link/link';
import EventIdFormatter from '../event-id-formatter/event-id-formatter';
import { Event } from '../../types/event';
import { OrderWithDetails } from '../../types/order';
import OrderSummary, { ORDER_SUMMARY_FORMAT } from '../order-summary/order-summary';
import { Customer } from '../../types/customer';
import { ORDER_STATUS } from '../../util/order-status';

interface OrderSidebarProps {
    order: OrderWithDetails | null;
    onEventShow: (eventId: Event['id']) => void;
    onCustomerShow: (customerEmail: Customer['email']) => void;
    onClose: () => void;
}

export default function OrderSidebar(props: OrderSidebarProps) {
    const [order, setOrder] = useState<OrderWithDetails | null>(null);

    useEffect(() => {
        let timeoutId: number | undefined;

        if (!props.order) {
            timeoutId = window.setTimeout(() => {
                setOrder(null);
            }, 300);
        } else {
            setOrder(props.order);
        }

        return () => window.clearTimeout(timeoutId);
    }, [props.order]);

    return (
        <Sidebar
            className={className.base}
            position={SIDEBAR_POSITION.LEFT}
            isOpen={!!props.order}
            onClose={props.onClose}
        >
            {order && (
                <>
                    <div className={className['top-section']}>
                        <div className={className.header}>
                            <div className={className.id}>#{order.id}</div>
                            <div className={className['close-button']} onClick={props.onClose}>
                                <FontAwesomeIcon icon="times" />
                                <div className={className.label}>cerrar</div>
                            </div>
                        </div>
                        <div className={className.details}>
                            <SidebarDetail label="Cliente">
                                <Link
                                    onClick={() => {
                                        props.onClose();
                                        props.onCustomerShow(order.customer.email);
                                    }}
                                >
                                    {order.customer.name}
                                </Link>
                            </SidebarDetail>
                            <SidebarDetail label="Recordatorio">
                                {order.event_id ? (
                                    <Link
                                        onClick={() => {
                                            props.onClose();
                                            props.onEventShow(order.event_id!);
                                        }}
                                    >
                                        <EventIdFormatter>{order.event_id}</EventIdFormatter>
                                    </Link>
                                ) : order.status === ORDER_STATUS.CANCELLED ? (
                                    'Sin recordatorio por cancelación de este pedido'
                                ) : order.status === ORDER_STATUS.FAILED ? (
                                    'Sin recordatorio por falla de este pedido'
                                ) : (
                                    'Esperando que se complete este pedido'
                                )}
                            </SidebarDetail>
                            <OrderSummary order={order} isOrderIdHidden format={ORDER_SUMMARY_FORMAT.SIMPLE} />
                        </div>
                    </div>
                </>
            )}
        </Sidebar>
    );
}
