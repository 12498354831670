import { makeAutoObservable, toJS } from 'mobx';
import { Store } from '../types/store';
import { BillingState } from '../types/billing-state';
import { getMappedObjects } from '../util/get-mapped-objects';
import { Http, HttpResponse } from './http';
import SessionStore from './session';

export default class BillingStateStore implements Store {
    private http: Http;
    billingStates: BillingState[];
    isIndexing = false;

    constructor(sessionStore: SessionStore) {
        this.http = new Http(sessionStore);
        this.billingStates = [];

        makeAutoObservable(this);
    }

    get path() {
        return 'billing-states';
    }

    get mappedBillingStates() {
        return getMappedObjects(toJS(this.billingStates));
    }

    *index() {
        this.isIndexing = true;

        const response: HttpResponse<BillingState[]> = yield this.http.getApi<BillingState[]>(this.path);

        if (response.successful && response.data) {
            this.billingStates = response.data.sort((billingStateA, billingStateB) =>
                billingStateB.name > billingStateA.name ? -1 : 1
            );
        }

        this.isIndexing = false;

        return response.successful;
    }
}
