import { DependencyList, useEffect, useRef } from 'react';

export const useDebounce = (callback: (...args: unknown[]) => void, timeout: number, deps?: DependencyList) => {
    const timeoutId = useRef<number>();

    useEffect(() => {
        clearTimeout(timeoutId.current);
        timeoutId.current = window.setTimeout(callback, timeout);

        return () => clearTimeout(timeoutId.current);
    }, deps);
};
