import { toJS } from 'mobx';
import { Mapped } from '../types/mapped';
import SessionStore from './session';
import { ROUTE } from '../util/route';
import { LOCAL_STORAGE_KEY } from '../util/local-storage-key';
import { API_URL } from '../util/api-url';

export enum HTTP_STATUS_CODE {
    OK = 200,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    DISCONNECTED = 777,
}

export interface HttpResponse<T> {
    successful: boolean;
    status: HTTP_STATUS_CODE;
    data?: T;
    id?: string | null;
}

enum CONTENT_TYPE {
    JSON = 'application/json',
}

enum METHOD {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

export class Http {
    private sessionStore: SessionStore;

    constructor(sessionStore: SessionStore) {
        this.sessionStore = sessionStore;
    }

    private getResponseBody = <T>(response: Response, data?: T) => {
        if (response.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            window.contextMessageController.onFailShow('sesión finalizada');
            localStorage.removeItem(LOCAL_STORAGE_KEY.LOGGED_IN_USER_ID);
            localStorage.removeItem(LOCAL_STORAGE_KEY.AUTH_KEY);
            window.historyController.push(ROUTE.LOGIN);
        }

        return {
            successful: response.status >= 200 && response.status < 300,
            data,
            status: response.status,
            id: response.headers.get('x-request-id'),
        };
    };

    private httpFetch = <T, U = undefined>(
        method: METHOD,
        path: string,
        data?: Partial<U extends undefined ? T : U>,
        disableAuthorization?: boolean
    ): Promise<HttpResponse<T>> => {
        return new Promise(resolve => {
            const headers: Record<string, string> = data instanceof File ? {} : { 'content-type': CONTENT_TYPE.JSON };
            if (!disableAuthorization) {
                headers['Authorization'] = `Bearer ${toJS(this.sessionStore.authToken)}`;
            }

            let url = `${API_URL}/${path}`;
            if (method === METHOD.GET && data) {
                url += (Object.entries(data) as string[][]).reduce((params, [key, value]) => {
                    return value !== undefined
                        ? !params
                            ? `?${key}=${encodeURI(value)}`
                            : `${params}&${key}=${encodeURI(value)}`
                        : params;
                }, '');
            }

            let body: string | FormData | null = data ? JSON.stringify(data) : null;
            if (data instanceof File) {
                body = new FormData();
                body.append('file', data);
            }

            fetch(url, {
                method,
                headers,
                credentials: 'same-origin',
                body: method !== METHOD.GET ? body : null,
            })
                .then(response => {
                    // FIXME:
                    window.setTimeout(() => {
                        response
                            .json()
                            .then((data?: T) => {
                                resolve(this.getResponseBody(response, data));
                            })
                            .catch(() => {
                                resolve(this.getResponseBody(response));
                            });
                    }, 0);
                })
                .catch(error => {
                    // FIXME:
                    window.setTimeout(() => {
                        window.contextMessageController.onFailShow(`${error}`);
                        resolve({
                            successful: false,
                            status: HTTP_STATUS_CODE.DISCONNECTED,
                        });
                    }, 0);
                });
        });
    };

    getApi = <T>(path: string, params?: Mapped<string | number>): Promise<HttpResponse<T>> => {
        return this.httpFetch<T, Mapped<string | number>>(METHOD.GET, path, params);
    };

    deleteApi = <T>(path: string): Promise<HttpResponse<T>> => {
        return this.httpFetch<T>(METHOD.DELETE, path);
    };

    postApi = <T, U = undefined>(
        path: string,
        data?: Partial<U extends undefined ? T : U>
    ): Promise<HttpResponse<T>> => {
        return this.httpFetch<T, U>(METHOD.POST, path, data);
    };

    putApi = <T, U = undefined>(
        path: string,
        data?: Partial<U extends undefined ? T : U>
    ): Promise<HttpResponse<T>> => {
        return this.httpFetch<T, U>(METHOD.PUT, path, data);
    };

    fileApi = <T>(path: string, file: File): Promise<HttpResponse<T>> => {
        return this.httpFetch<T, File>(METHOD.POST, path, file);
    };
}
